class DBStorage {

    static set(key:any, value:any) {
        if(value !== null){
            window?.localStorage.setItem(key, value) 
        }
    }

    static get(key:any, defaultValue:any) {
        return window?.localStorage.getItem(key) || defaultValue || false;
    }

    static remove(key:any) {
        if(key !== null){
            window?.localStorage.removeItem(key)
        }
    }
}



export default DBStorage 