import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import * as serviceWorker from "serviceWorker";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "theme";
import ServerContext from "./context/ServerContext";
import ContextProviderWrapper from "./context/ContextProvider";
import ErrorBoundary from './utils/errorBoundary'
import {loadableReady} from '@loadable/component';
import { ToastContainer, Slide, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

declare global {
  interface Window {
    initialData: any;
  }
}

const initialData = window && window.initialData;

let value: any = {};

if (window && window.initialData) {
  value = initialData;
}

loadableReady(() => {
  ReactDOM.hydrate(
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={"/"}>
          <ServerContext.Provider value={value}>
            <ToastContainer
              limit={1}
              position={window && window.innerWidth <= 767 ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_LEFT}
              autoClose={3000}
              hideProgressBar={true}
              className='Toastify__toast-container'
              transition={Slide}
            />
              <ContextProviderWrapper>
                <App />
              </ContextProviderWrapper>
          </ServerContext.Provider>
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
})
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
