import * as interfaces from "../interfaces";

export const initialState: interfaces.user = {
    userData: {},
    donateUnit: [],
    hasGoogleToken: false,
    isMedicalWarningModal:false
};

export const reducer = (state: any, action: interfaces.action) => {
  switch (action?.type) {
    case "gift-cards":
      return { ...state, giftCards: action.value}
    case "user-data":
      return { ...state,  userData: action.value  };
    case "donate-unit-data":
      return { ...state, donateUnit: action.value };
    case "set-has-google-token":
      return { ...state, hasGoogleToken: action.value };
      case "set-medical-warning-modal":
        return { ...state, isMedicalWarningModal: action.value };
    case "reset":
      return initialState;
  }
};

