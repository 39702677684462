import React from "react";
// import SuperAdminDashboard from "./SuperAdminDashboard";
//-------Rewamp UI Component-------
import FundraiserDetailsPage from "../components/UI-Rewamp/FundraiserDetails/fundraiserDetails";
// --------------------------------
import useServerEffect from "../useServerEffect";
import { useParams } from "react-router-dom";
import fetch from "isomorphic-fetch";
import { BASE_URL } from "../config";

const ContainerComponent = () => {
  const slug: any = useParams();
  
  useServerEffect([], "campaignData", () => {
    return fetch(`${BASE_URL}/api/campaigns/${slug.identifier}`).then((res) => {
      return res.json();
    });
  });

  return (
    <>
      <FundraiserDetailsPage />
    </>
  );
};

export default ContainerComponent;
