import { Theme } from "@material-ui/core/styles";
import React, { useContext, useState, useEffect, useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/core/styles/useTheme";
import ServerContext from "../../../context/ServerContext";
import Context from "../../../context/Context";
import loadable from '@loadable/component';
import { COLORS } from '../../../assets/variables';
import { hasTaxExemption } from "../../../utils/helpers";
import Vector from "../../../assets/images/vector.png";

const ChildCampaignTitle = loadable(() => import("../../ChildCampaingComponents/ChildCampaignTitle"));
const SubHeaderTitle = loadable(() => import("./SubHeaderTitle"));
const ChipComponent = loadable(() => import("./chip"));

const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      fontWeight: "bold",
      textAlign: "center",
      fontFamily: "'Libre Baskerville', 'serif'",
    },
    headerMobile: {
      textAlign: "center",
      fontFamily: "'Libre Baskerville', 'serif'",
    },
    titleMobile: {
      fontWeight: 400,
    },
    gifrHeader: {
      marginBottom: 32,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 20
      }
    },
    campaignTitle: {
      marginBottom: 16,
      fontSize: '2.5rem',
      color: COLORS.primaryTitleText,
      fontWeight: 700,
      maxWidth: '100%',
      '@media (max-width:767px)': {
        fontSize: '1.125rem',
        marginTop: 20,
        marginBottom: 10,
        width: '100%'
      },
    },
    chipCircle: {
      width: 5,
      height: 5,
      borderRadius: 5,
      background: COLORS.secondaryText,
      margin: '0px 8px',
    },
    mobileHeader: {
      // marginTop: 15,
    },
    campaignTaxLabel: {
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    verifiedWrapper: {
      display: "flex"
    }
  }));

const Header = React.memo((props: any) => {
  const serverContext: any = useContext(ServerContext);
  const context = useContext(Context);
  const classes = useHeaderStyles()
  const theme = useTheme();
  const [showTaxTag, setShowTaxtag] = useState(true);
  const campaignContext = context?.Campaign;
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  let campaignData = serverContext?.campaignData
    ? serverContext?.campaignData
    : campaignContext?.state?.campaignData;

  const config = context?.Config;
  const { currencyCodeData } = config?.state?.currency;

  const isSuperCampaignUnderlyingFundraiser = useMemo(() => {
    const { supercampaign_id } = campaignData || ''
    return supercampaign_id && supercampaign_id.length > 0
  }, [campaignData])

  const isSuperCampaignTaxExempt = useMemo(() => {
    const { is_supercampaign, supercampaign } = campaignData || {}
    return is_supercampaign && supercampaign?.is_tax_exempt
  }, [campaignData])

  useEffect(() => {
    const exemptions = campaignData?.nonprofit?.exemptions;
    const can_provide_10be = campaignData?.nonprofit?.can_provide_10be;
    // If the parent organisation is not a nonprofit, don't show the the tax exempt tag 
    if (campaignData?.nonprofit.hasOwnProperty('is_nonprofit') && campaignData.nonprofit.is_nonprofit === false) {
      setShowTaxtag(false);
    }
    else {
      if (currencyCodeData == "INR" && hasTaxExemption(exemptions, currencyCodeData, can_provide_10be)) {
        setShowTaxtag(true);
      } else if (currencyCodeData === 'USD' && hasTaxExemption(exemptions, currencyCodeData, can_provide_10be)) {
        setShowTaxtag(true);
      } else {
        setShowTaxtag(false)
      }
    }
  }, [campaignData, currencyCodeData])

  return (
    <Grid className={classes.gifrHeader}>
      {isSmall ? (
        <Grid className={classes.mobileHeader}>
          <Grid className={classes.verifiedWrapper}>
            {campaignData?.is_medical_fundraiser &&
              <>
                {showTaxTag && campaignData?.is_giveindia_verified ?
                  <Grid>
                    <ChipComponent chip={Vector} chipName={"Vector"} size="small" label="Verified" textcolour={"#E7424B"} bgcolor={"#EB57571A"} />
                  </Grid> :
                  <ChipComponent size="small" label="Verification Pending" textcolour={"#E7424B"} bgcolor={"#EB57571A"} />
                }
              </>
            }
            {isSuperCampaignTaxExempt ? <ChipComponent size="small" label="Tax Benefits Available" textcolour={"#D97613"} bgcolor={"#FDF2E2"} /> :
              (showTaxTag && !campaignData?.parent_data) ? <ChipComponent size="small" label="Tax Benefits Available" textcolour={"#D97613"} bgcolor={"#FDF2E2"} /> : null}
          </Grid>
          <Typography variant="h1" className={classes.campaignTitle} title={campaignData?.title}>{campaignData?.title}</Typography>
          {!campaignData?.parent_data && <SubHeaderTitle ngoName={campaignData?.nonprofit?.name} isSuperCampaign={isSuperCampaignUnderlyingFundraiser} />}
        </Grid>
      ) : (
        <>
          <Typography variant="h1" className={classes.campaignTitle} title={campaignData?.title}>{campaignData?.title}</Typography>
          {!campaignData?.parent_data &&
            <Grid className={classes.campaignTaxLabel}>
              <Grid item>
                <SubHeaderTitle ngoName={campaignData?.nonprofit?.name} isSuperCampaign={isSuperCampaignUnderlyingFundraiser} />
              </Grid>
              {isSuperCampaignUnderlyingFundraiser ? null :
                <>
                  {
                    campaignData?.is_medical_fundraiser &&
                    <>
                      <Grid><div className={classes.chipCircle}></div></Grid>
                      {showTaxTag && campaignData?.is_giveindia_verified ?
                        <ChipComponent chip={Vector} chipName={"Vector"} size="small" label="Verified" textcolour={"#E7424B"} bgcolor={"#EB57571A"} />
                        :
                        <ChipComponent size="small" label="Verification Pending" textcolour={"#E7424B"} bgcolor={"#EB57571A"} />
                      }
                    </>
                  }
                  {isSuperCampaignTaxExempt ? <ChipComponent size="small" label="Tax Benefits Available" textcolour={"#D97613"} bgcolor={"#FDF2E2"} /> :
                    showTaxTag && !campaignData?.parent_data ?
                      <React.Fragment>
                        <Grid item>
                          <ChipComponent size="small" label="Tax Benefits Available" textcolour={"#D97613"} bgcolor={"#FDF2E2"} />
                        </Grid>
                      </React.Fragment>
                      : null
                  }
                </>
              }
            </Grid>
          }
        </>
      )}
    </Grid>
  );
});

export default Header;
