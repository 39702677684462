import * as interfaces from "../interfaces";

export enum NonProfitFundraiserTypes {
    POPULAR = 'POPULAR',
    OTHER = 'OTHER'
}

export const initialState: interfaces.NonProfitState = {
    nonProfitDetails:{
        id: "",
        name: "",
        slug: "",
        amountRaised: 0,
        supporterCount: 0,
        description: "",
        externalProfiles: [],
        address: {
            countryCode: "",
            areaCode: "",
            country: "",
        },
        causes: [],
        exemptions: [],
        taxCertifications: [],
        createdAt: "",
        recognitions: [],
        gallery: [],
        campaigns: [],
        recentUpdates:[],
        coverImage:'',
        panCard: '',
        fcra: '',
        regNo: '',
        phoneNo:'',
        emailId:'',
        members:[],
        metaTitle:"",
        metaDescription:"",
        ngoInsights:[],
        isPublished:true,
        status:'',
        canAcceptDonationsWithoutFcra: false,
    },
    recentSupporters: [],
    similarNgos: [],
    popularFundraisers: [],
    popularFundraisersCount:0,
    otherFundraisers:[],
    otherFundraisersCount:0,
    popularFundraiserIds:[],
    isOtherFundraisersLoaded:false,
    isPopularFundraisersLoaded:false,
    allCauses:[]
};

export const reducer = (state: any, action: interfaces.action) => {
    switch (action?.type) {
        case "add-nonprofit-details":
        return { ...state,  nonProfitDetails: action.value };
        case "add-recent-supporters":
            return { ...state,  recentSupporters: action.value  };
        case "add-similar-ngos":
            return { ...state,  similarNgos: action.value  };
        case "add-popular-fundraisers":
            return {...state, popularFundraisers:action.value}
        case "popular-fundraisers-loaded":
                return {...state, isPopularFundraisersLoaded:action.value}
        case "add-other-fundraisers":
            return {...state, otherFundraisers:action.value}
        case "other-fundraisers-loaded":
                return {...state, isOtherFundraisersLoaded:action.value}
        case "add-nonprofit-popular-fr-ids":
                return {...state, popularFundraiserIds:action.value}
        case "add-nonprofit-popular-frs":
            return {...state, popularFundraisers:action.value}
        case "add-nonprofit-popular-fr-count":
            return {...state, popularFundraisersCount:action.value}
        case "add-nonprofit-other-frs":
            return {...state, otherFundraisers:action.value}
        case "add-nonprofit-other-fr-count":
            return {...state, otherFundraisersCount:action.value}
        case "add-nonprofit-is-other-fr-loaded":
            return {...state, isOtherFundraisersLoaded:action.value}
        case "add-nonprofit-is-popular-fr-loaded":
            return {...state, isPopularFundraisersLoaded:action.value}
        case "add-all-causes":
            return {...state, allCauses:action.value}
        case "reset":
        return initialState;
    }
};
