import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { COLORS } from '../../../assets/variables';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { constantImages as IMG_CONST } from "../../../imageConstant";
import VideoPlayer from "../../HomepageRevamp/VideoPlayer";
import { trackData } from '../../../utils/helpers';
import Context from '../../../context/Context';
import Analytics from '../../../utils/analytics';

type ImgProps = {
  src: string,
  placeholder?: string,
  height?: string,
  width?: string,
  alt: string,
  className?: string,
  style?: React.CSSProperties,
  minHeight?: string,
  aspectRatio?: string,
  isMinHeightRequired?: boolean,
  divStyle?: React.CSSProperties,
  imageIndex?: number,
  visibleByDefault?: boolean,
  isVideoPresent?:boolean,
  videoUri?:String,
  setAddStickyButtonOnVideo?:any,
  renderGiveNow?:any,
  thumbnailDataForVideo?:any,
  isNgoProfileVideo?:boolean,
  ngoData?:any,
  utmParams?:any,
  wrapperClassName?:string
}

const LazyLoadImages = (image: ImgProps) => {
  const [style, setStyle] = useState<any>({ minHeight: image?.minHeight })
  const [bgImage, setBgImage] = useState<any>(COLORS.footerBgColor)
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const context = useContext(Context);
  const userContext = context?.User;
  const userData = userContext?.state?.userData;
  const handleAfterLoad = () => {
    if (!image.isMinHeightRequired) {
      setStyle({ minHeight: "100%", })
      setBgImage('unset')
    }
  }

  const onVideoModalOpen = () => {
    setIsVideoModalOpen(true)
    image?.thumbnailDataForVideo()
    document?.body?.classList.add('modal-open');
    image?.setAddStickyButtonOnVideo(true)
  }

  const onVideoModalClose = () => {
    setIsVideoModalOpen(false)
    document?.body?.classList.remove('modal-open');
    image?.setAddStickyButtonOnVideo(false)
  };

  useEffect(() => {
    if(isVideoModalOpen && image.isNgoProfileVideo) {
      const ctaAnalytics = trackData('video_popup');
      const eventData = {
        nonProfitData: image?.ngoData || '',
        utmParameters: image?.utmParams || '',
        cta_type: ctaAnalytics?.cta_type,
        user:userData,
      }
      Analytics.amplitudeEvents("ngoPlayVideo", eventData);
    }
  }, [isVideoModalOpen])

  return (<>
    <div className="slider-img-wrapper" 
    onClick={() => image?.isVideoPresent ? onVideoModalOpen() : null}
    style={{
      minHeight: style?.minHeight,
      background: !isSmall ? bgImage : 'transparent',
      ...image?.divStyle,
      position: image?.isVideoPresent ? 'relative' : undefined,
      cursor: 'pointer'
    }}>
      {image?.isVideoPresent ? <img src="https://cfstatic.give.do/5fd43a48-dc26-4dbf-9c78-19015ac121e2.webp" 
      alt="video" className="videoImg" /> : null}
      <LazyLoadImage
        style={{ ...image?.style }}
        className={image?.className}
        afterLoad={handleAfterLoad}
        alt={image.alt}
        src={image.src}
        height={image?.height}
        effect="blur"
        visibleByDefault={image?.visibleByDefault && image?.imageIndex == 0 || false}
        wrapperClassName={image?.wrapperClassName}
        // placeholderSrc={IMG_CONST?.PLACEHOLDER}
        // placeholderSrc={image?.placeholder}
        width={image?.width} />
    </div>
    {
        <VideoPlayer isOpen={isVideoModalOpen} 
        renderGiveNow={image?.renderGiveNow}
        closeModal={() => onVideoModalClose()} videoUri={image?.videoUri} /> 
    }
  </>)
}
export default LazyLoadImages;