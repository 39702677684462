import React, { useEffect, useContext, useState, useRef, } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import loadable from '@loadable/component';
import { Helmet } from "react-helmet";
import Context from "../../../context/Context";
import clsx from 'clsx';
import InApp from 'detect-inapp';
import ServerContext from "../../../context/ServerContext";
import { checkForImageInGallery, getAmountRatio } from "../../../utils/dataModifiers";
import { convertAmount, DONATION_TYPE_LIST, getDeviceType, getUTMParameters, renderGiveNowButtonText, isScrolledIntoView, getUserLocation, triggerAddToCarytAnalytics, trackData, getUTMStoredInCookie, getImageDimensions, getTags, determineIfAllowedForTicker, parseJSONSafe } from "../../../utils/helpers";
import { AMP_DEPLOYMENT_KEY, AMP_DEPLOYMENT_KEY_SOCIAL_PROOFING_EXP, AMP_EXP_1, AMP_HOMEPAGE_BANNER_EXP, AMP_SOCIAL_PROOFING_EXP, BASE_URL, EXPERIMENT_CAMPAIGN_ID } from "../../../config";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { donationPost, noAuthGet, post, trackFunnel } from "../../../utils/axiosClient";
import FundraiserSkeleton from "../FundraiserSkeleton/FundraiserSkeleton";
import FundraiserStats from "../FundraiserStats/fundraiserStats";
import { constantImages as IMG_CONST } from "../../../imageConstant";
import Navbar from "../Navbar/fundraisersNavbar";
import CarouselPanel from "../../../components/NewHomePage/CarouselPanel";
import Header from "../Header/Header";
import EditButton from "../EditComponents/EditButton/EditButton";
import Analytics from "../../../utils/analytics";
import { useFundraiserDetailsPageStyles } from "./fundraiserDetails.style";
import { apiRoutes as API, pageRoutes } from "../../../routeConstant";
import axios from "axios";
import ChildCampaignTitle from "../../../components/ChildCampaingComponents/ChildCampaignTitle";
import { initExperiment, logAmplitudeEvent } from "../../../utils/amplitude";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ShareIcon from '@material-ui/icons/Share';
import { toast } from 'react-toastify';
import { DonationFormData } from "utils/types";
import deviceInfoContext from "../../../components/NewHomePage/renderByDevice/deviceInfoContext";
import CharityIcon from '../../../assets/images/fundraiser-details/CharityIcon.svg';
import DBStorage from "../../../utils/DBStorage";

import Snackbar from '@material-ui/core/Snackbar';
import Alert from "@material-ui/lab/Alert";
import Slide from '@material-ui/core/Slide';
const SocialProofingSnackbar = loadable(() => import("./SocialProofingSnackbar"));
const FundraiserDonorsTicker = loadable(() => import("./FundraiserDonorsTicker"));
const DonateViaSection = loadable(() => import("../DonateViaSection/DonateViaSection"));
const ShareNowScreen = loadable(() => import("../ShareNowScreen/ShareNowScreen"));
const Loader = loadable(() => import("../../../components/common/Loader/Loader"));
const VerticalDonorLists = loadable(() => import("../DonorLists/VerticalDonor/VerticalDonorLists"));
const TnCWarningModal = loadable(() => import("../../../components/HomePage/Modal/TnCWarningModal"));
const Footer = loadable(() => import("../../HomePage/Footer/Footer"));
const FaqsTab = loadable(() => import("../CampaignDetails/FAQsTab/FaqsTab"));
const CampaignDetails = loadable(() => import("../CampaignDetails/CampaignDetails"));
const AboutGiveSection = loadable(() => import("../AboutGiveSection/AboutGiveSection"));
const Invitation = loadable(() => import("../../../components/UI/Invitation/Invitation"));
const VerificationFooter = loadable(() => import("../VerificationFooter/index"));

type ShareData = {
    title?: string;
    text?: string;
    url?: string;
    files?: any;
};

export interface SupporterData {
    subscription_id: string;
    give_subscription_id: string;
    currency_code: "INR" | "USD";
    amount: number;
    tip_percent: number;
    amount_in_inr: number;
    campaign: {
        _id: string;
        title: string;
        slug: string;
        permalink: string;
        image: string;
    };
    nonprofit: {
        name: string;
        is80gValid: boolean;
        _id: string;
        slug: string;
        '80g': string;
        permalink: string;
    };
    user: {
        profile_pic: string;
        name: string;
        address: {
            country_code: string;
            city: string;
        };
    };
    created_at: string;
    is_anonymous: boolean;
    parent_donation: string;
    tip_amount: number;
    tip_amount_in_inr: number;
    receipt_reason: string;
}

declare global {
    interface Window {
        fcWidget: any;
        getUTMParameters: any;
        experiment: any;
    }
}
interface Navigator {
    share?: (data?: ShareData) => Promise<void>;
    canShare?: (data?: ShareData) => boolean;
}

const FundraiserDetails = React.memo((props: any) => {
    const classes = useFundraiserDetailsPageStyles()
    const history = useHistory();
    const slug: any = useParams();
    const [progressVal, setProgressVal] = useState(0)
    const [loading, setLoading] = useState(true)
    const context = useContext(Context);
    const appContext = useContext(deviceInfoContext);
    const campaignContext = context?.Campaign;
    const toastContext = context?.Toast;
    const userContext = context?.User;
    const userData = userContext?.state?.userData;
    const serverContext: any = useContext(ServerContext);
    const [donorModal, setDonorModal] = useState(false);
    const recentDonors: Array<SupporterData> = campaignContext?.state?.donors?.recentDonors;
    const filteredDonorList = recentDonors;
    let campaignData = serverContext?.campaignData || campaignContext?.state?.campaignData;

    const identifierId = campaignData?.id;
    const donors = campaignContext?.state?.donors
    const sharesData = campaignContext?.state?.sharesData
    const shareCount = sharesData?.shareCount;
    const config = context?.Config;
    const userIPContext = context?.userIPContext.state.ipData;
    const countries = config?.state?.countries;
    const [openShareNowModal, setOpenShareNowModal] = useState(false)
    const donationRef = useRef<HTMLDivElement>(null);
    const aboutGiveRef = useRef<HTMLDivElement>(null);
    const getStoryDivPositionRef = useRef<HTMLDivElement>(null)
    const [showSticky, handleShowSticky] = useState(false);
    const { currencyList, currencyCodeData, currencyExchangeData, currencySymbolData } = config?.state?.currency;
    const [refId, setRefId] = useState<any>(null);
    const [shareType, setShareType] = useState<any>(null);
    const [utmParameters, setUtmParameters] = useState<any>(null)
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isTnCModalOpen, setIsTnCModalOpen] = useState(false);
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [slidesList, setSlidesList] = useState<Array<any>>([]);
    const showVariant1 = true;
    const [contentVisible, setContentVisible] = useState<Boolean>(false);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [shareCountState, setShareCountState] = useState(0)
    const [showFundraiserStats, setShowFundraiserStats] = useState<boolean>(true)
    const [geoLocation, setGeoLocation] = useState<any>(null);
    const [productDetailAdded, setProductDetailAdded] = useState(false);
    const [addStickyButtonOnVideo, setAddStickyButtonOnVideo] = useState(false);
    const [showSocialProofingVariant, setShowSocialProofingVariant] = useState(false);
    const [removeTicker, setRemoveTicker] = useState(false);
    const [isStickyHeader, setIsStickyHeader] = useState(false);
    const [experiment, setExperiment] = useState('')
    const [variant, setVariant] = useState('')
    let isToastTriggered = false;
    const timeoutsList: Array<number> = [];
    const [socialProofVariant, setSocialProofVariant] = useState<string>('');
    const [isHNI, setIsHNI] = useState(false);

    const [matchingFundText, setMatchingFundText] = useState('');
    const [showMatchingFundsVariant, setShowMatchingFundsVariant] = useState(false);
    const [SDKVariant, setSDKVariant] = useState(typeof window !== 'undefined' ? (window?.localStorage.getItem(AMP_EXP_1) || null) : null);

    // Snackbar 
    const [recentDonorData, setRecentDonorData] = useState({});
    const [countriesData, setCountriesData] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleClose = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    //Get the total Share count of the campaign
    useEffect(() => {
        setShareCountState(shareCount)
    }, [shareCount])

    const recentDonationsTrigger = async () => {
        if (!isToastTriggered) {
            if (filteredDonorList?.length > 0 && config?.state?.countries?.length > 0) {
                const finalList = filteredDonorList?.filter((donor: any) => !donor.is_anonymous).slice(0, 10);
                isToastTriggered = true;
                finalList.forEach((donor: SupporterData, index: number) => {
                    displaySnackBarWithDelay(donor, config?.state?.countries, index)
                })
            }

        }
    }

    const displaySnackBarWithDelay = (donor: SupporterData, countries: any, i: number) => {
        const timeoutValue = 5000 + (i * (6000));
        const toastTimeout = window?.setTimeout(() => {
            setSnackbarOpen(true);
            setRecentDonorData(donor);
            setCountriesData(countries)
        }, timeoutValue);
        timeoutsList.push(toastTimeout);
    }

    const isPromoted = campaignData?._tags.some((element: any) => {
        if (element && element.slug && (element.slug === "promotedfundraiser")) {
            return true
        } else {
            return false
        }
    })

    // const isHNI = campaignData?._tags.some( (element: any) => {
    //     if (element && element.slug && element.slug === 'hni') {
    //         return true
    //     } else {
    //         return false
    //     }
    // })

    //creating function to load ip address from the API
    const getIP = async () => {
        try {
            const res = await axios.get('https://geolocation-db.com/json/')
            return res?.data?.IPv4
        }
        catch (e) { }
    }

    useEffect(() => {
        if (campaignData && campaignData?._id) {
            const isHni = campaignData?._tags.some((element: any) => {
                if (element && element.slug && element.slug === 'hni') {
                    return true
                } else {
                    return false
                }
            })
            setIsHNI(isHni);
            if (currencyCodeData === 'INR' && isPromoted && !isHni) {
                let matchingText: any = DBStorage.get(`matchingFundText`, false);
                setMatchingFundText(matchingText)
                if (SDKVariant && SDKVariant === 'variant-2') {
                    setShowMatchingFundsVariant(true)
                } else {
                    let variant = typeof window !== 'undefined' ? (window?.localStorage.getItem(AMP_EXP_1) || null) : null;
                    if (variant && variant === 'variant-2') {
                        setSDKVariant(variant)
                        setShowMatchingFundsVariant(true)
                    } else {
                        setShowMatchingFundsVariant(false)
                    }
                }
            }
            else {
                // setLoading(false);
                setShowMatchingFundsVariant(false)
            }
        }
    }, [campaignData, SDKVariant, appContext])

    useEffect(() => {
        if (isPromoted && !socialProofVariant) {
            initExperiment(AMP_SOCIAL_PROOFING_EXP, AMP_DEPLOYMENT_KEY_SOCIAL_PROOFING_EXP)
                .then((res: any) => {
                    if (res && res.experiment == AMP_SOCIAL_PROOFING_EXP) {
                        setSocialProofVariant(res.variant);
                    }
                })
        }
    }, [isPromoted, socialProofVariant])

    useEffect(() => {
        if (filteredDonorList && filteredDonorList.length > 0 && isPromoted && socialProofVariant) {
            if (socialProofVariant && socialProofVariant === 'variant-2') {
                setShowSocialProofingVariant(true)
                recentDonationsTrigger();
            }
        } else {
            setShowSocialProofingVariant(false)
        }
    }, [filteredDonorList, isPromoted, socialProofVariant, config])

    useEffect(() => {
        if (!productDetailAdded && campaignData) {
            const gtmData = {
                id: campaignData?._id,
                name: campaignData?.slug || "",
                title: campaignData?.title || "",
                brand: campaignData?.nonprofit.name || "",
                category:
                    campaignData?.project && campaignData?.project?.causes
                        ? campaignData.project.causes[0] || ""
                        : "",
                variant: "Campaign",
                nonprofit_slug: campaignData?.nonprofit.slug || undefined,
                donation_currency: currencyCodeData,
            };
            const createLandingPageLink = window?.location.href
            const ampData = {
                campaignData: campaignData,
                currency: currencyCodeData,
                utmParameters: utmParameters,
                location: geoLocation,
                landing_page_link: createLandingPageLink !== undefined ? createLandingPageLink : '',
                user: userData,
                variant: SDKVariant
            }
            logAmplitudeEvent('fundraiserPage', ampData);
            trackFunnel('LANDINGPAGE', {campaignData:campaignData, user: userData, userAgent: navigator?.userAgent})
            getIP().then((ip) => {
                Analytics.productDetail(gtmData, userData, utmParameters, ip);
            })
            setProductDetailAdded(true);

        }
    }, [slug.identifier, campaignData]);


    const thumbnailDataForVideo = async () => {
        const ampData = {
            campaignData: campaignData,
            currency: currencyCodeData,
            utmParameters: utmParameters,
            location: geoLocation,
            user: userData,
            fundraiser_title: campaignData?.title,
        }
        await logAmplitudeEvent('thumbnailClicked', ampData);
    }

    //Getting the UTM parameters 
    useEffect(() => {
        const utmParams: any = getUTMParameters(location?.search);
        if (utmParams) {
            setUtmParameters(utmParams)
        }
    }, [location])

    useEffect(() => {

        if (typeof window === "object") {
            const handleScroll = () => {
                let position = window?.scrollY;
                const supportCampaignHeight = document.getElementById("support-campaign")?.clientHeight;
                let element = document.getElementById("sticky-header");
                if (element && window?.innerWidth < 600) { // xs is same as 600 try with media query but dont work So added here 600
                    if (position && position > (supportCampaignHeight ? supportCampaignHeight : 82)) {
                        element.classList.add("sticky");
                        setIsStickyHeader(true)
                    } else {
                        element.classList.remove("sticky");
                        setIsStickyHeader(false)
                    }
                }
            }
            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
                timeoutsList.forEach((timeout) => clearTimeout(timeout));
            };
        }
    }, [])

    useEffect(() => {
        if (geoLocation === null) {
            //Removed geo location code, explore other geolocation services
        }
    }, [geoLocation])

    //Currency info get from config API
    useEffect(() => {
        const queryString = window?.location.search;
        const urlParams = new URLSearchParams(queryString);
        const ref_id = urlParams.get('ref')
        const share_type = urlParams.get('type')
        setRefId(ref_id)
        setShareType(share_type)
    }, []);

    // Added skeleton loading component that will be loaded until campaign detail API response added into the context
    useEffect(() => {
        // if (campaignData?.statistic && donors?.topDonors && donors?.donationUserActivityInfo) {
        if (campaignData?.statistic) {
            setLoading(false); // Commenting this line as the same done on the experiment loadin. This is done to avoid flickering in amplitude experiment loading. 
            campaignContext?.dispatch({
                type: "campaign-data",
                value: campaignData,
            });
            // If raised amount isn't greated than show_progoress amount, then set the stats visibility to false            
            if (
                campaignData?.show_progress_after_amount &&
                (
                    campaignData?.statistic?.raised_amount ||
                    campaignData?.statistic?.raised_amount === 0
                ) &&
                campaignData?.statistic.raised_amount < campaignData?.show_progress_after_amount
            ) {
                setShowFundraiserStats(false);
            }
        }
    }, [campaignData])

    useEffect(() => {
        if (campaignData) {
            setProgressVal(getAmountRatio(campaignData?.target_amount, campaignData?.statistic?.raised_amount))
            filterGalleryImages();
            defaultSelectionAmount(currencyCodeData);
        }
    }, [campaignData, currencyCodeData]);

    function getVideoIdFromLink(link: any) {
        const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
        const regex = new RegExp(pattern);
        const match = link.match(regex);

        if (match && match.length > 0) {
            const url = new URL(link);
            const searchParams = new URLSearchParams(url.search);
            let fetchVideoId = searchParams.get('v')
            if (fetchVideoId !== null) {
                return searchParams.get('v');
            } else {
                let videoId = url.pathname.substr(1); // Remove the leading slash
                if (videoId.includes('embed/')) {
                    videoId = videoId.split('embed/')[1]; // Extract the video ID from the "embed/" format
                }
                return videoId;
            }

        }
        throw new Error('Invalid YouTube link');
    }

    //To filter the gallery images from campaign data for the carousel panel 
    const filterGalleryImages = () => {
        let img: any = []
        campaignData?.gallery.filter((item: any, idx: number) => {
            if (item?.media_type === "gallery_video") {
                let videoId = getVideoIdFromLink(item?.uri);
                const thumbnailUrl =item.thumbnail ? item.thumbnail :  `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
                let src: object = {
                    "mobile": thumbnailUrl,
                    "desktop": thumbnailUrl
                }
                img.push({
                    src: src,
                    altText: 'Gallery Video',
                    videoLink: item?.uri,
                })
            }
            if (item?.media_type === "gallery_image") {
                let src: object = {
                    "mobile": item?.uri,
                    "desktop": item?.uri
                }
                img.push({
                    src: src,
                    altText: 'Gallery Image'
                })
            }
        })
        if (img.length == 0) {
            let src: object = {
                "mobile": IMG_CONST.DEFAULT_CAMPAIGN_IMAGE,
                "desktop": IMG_CONST.DEFAULT_CAMPAIGN_IMAGE
            }
            img.push({
                src: src,
                altText: 'Gallery Image'
            })
        } else {
            img.map((item: any) => {
                if (item.altText === 'Gallery Video') {
                    // method for adding fallback image for youtube thumbail if image is default one
                    getImageDimensions(item.src.desktop)
                        .then((dimensions: any) => {
                            if (dimensions.width === 120 && dimensions.height === 90) {
                                let videoId = getVideoIdFromLink(item?.videoLink);
                                let fallbackThumbnailImage = `https://img.youtube.com/vi/${videoId}/0.jpg`
                                item.src.desktop = fallbackThumbnailImage
                                item.src.mobile = fallbackThumbnailImage
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error.message);
                        });
                }
            })
        }
        setSlidesList(img)
    }

    const handleCloseModal = () => {
        setOpenShareNowModal(false)
    }

    const handleWhatsappShare = async (experiment_id: any, variant: any, mode: any) => {
        setExperiment(experiment_id);
        setVariant(variant);
        let surl: any;
        const inApp = new InApp(navigator.userAgent || navigator.vendor)
        if (inApp?.isInApp || inApp?.isMobile || getDeviceType() == "mobile") {
            post(API.LINK_SURL_NEW, {
                url: campaignData?.canonical_url,
                metadata: {
                    type: mode === 'whatsapp' ? 'whatsapp_direct' : 'share_mobile_1',
                    id: campaignData?._id,
                    utm_source: mode === 'whatsapp' ? 'whatsapp_direct' : 'share_mobile',
                    utm_content: campaignData?.slug,
                    utm_campaign: 'CampaignShare',
                    utm_medium: mode === 'whatsapp' ? 'ShareWhatsappButton' : "ShareButton",
                    utm_term: "ShareOnMobile",
                    variant: variant,
                    experiment_id: experiment_id
                },
            }).then(async (response) => {
                if (response.data.data.surl) {
                    surl = response.data?.data?.surl;
                    let navigatorData: any = global.window.navigator;
                    // if (surl && navigatorData?.userAgentData?.mobile) {
                    if (surl && mode === 'share') {
                        let shareData = {
                            title: campaignData?.title,
                            url: surl,
                            text: "Hi there, I am supporting a fundraiser that aims to change the lives of the underprivileged. It would be great if you could support this fundraiser with a contribution and help spread the word at "
                        }
                        if (navigatorData?.canShare(shareData)) {
                            await navigatorData?.share(shareData)
                                .then(() => {
                                    campaignContext.dispatch({
                                        type: "increment-share",
                                    })
                                })
                                .catch(() => console.log('Error sharing'));
                        }
                        else setOpenShareNowModal(true)
                    } else if (surl && mode !== 'share') {
                        let shareText = "Hi there, I am supporting a fundraiser that aims to change the lives of the underprivileged. It would be great if you could support this fundraiser with a contribution and help spread the word at " + surl
                        // window.open(`whatsapp://send?text=${shareText}`, "_blank");
                        window.location.assign(`whatsapp://send?text=${shareText}`);
                    }
                    else setOpenShareNowModal(true)
                }
                else {
                    setOpenShareNowModal(true)
                }
            }).catch(err => console.log('error in creating surl'));
        }
        else {
            if (mode === 'share') {
                setOpenShareNowModal(true)
            } else {
                post(API.LINK_SURL_NEW, {
                    url: campaignData?.canonical_url,
                    metadata: {
                        type: mode === 'whatsapp' ? 'whatsapp_direct' : 'copy-link',
                        id: campaignData?._id,
                        utm_source: mode === 'whatsapp' ? 'whatsapp_direct' : 'CopyLink',
                        utm_content: campaignData?.slug,
                        utm_campaign: 'CampaignShare',
                        utm_medium: mode === 'whatsapp' ? 'ShareWhatsappButton' : "ShareButton",
                        utm_term: "ShareOnDesktop",
                        variant: variant,
                        experiment_id: experiment_id
                    },
                }).then(async (response) => {
                    if (response.data.data.surl) {
                        surl = response.data?.data?.surl;
                        let shareText = "Hi there, I am supporting a fundraiser that aims to change the lives of the underprivileged. It would be great if you could support this fundraiser with a contribution and help spread the word at " + surl
                        window.open(`https://web.whatsapp.com/send?phone=&text=${shareText}&app_absent=0`, "_blank");
                    }

                });
            }
        }
    }

    const handleShare = async () => {
        setExperiment('');
        setVariant('');
        let surl: any;
        const inApp = new InApp(navigator.userAgent || navigator.vendor)
        if (inApp?.isInApp || inApp?.isMobile || getDeviceType() == "mobile") {
            post(API.LINK_SURL_NEW, {
                url: campaignData?.canonical_url,
                metadata: {
                    type: 'share_mobile_1',
                    id: campaignData?._id,
                    utm_source: 'share_mobile',
                    utm_content: campaignData?.slug,
                    utm_campaign: 'CampaignShare',
                    utm_medium: "ShareButton",
                    utm_term: "ShareOnMobile"

                },
            }).then(async (response) => {
                if (response.data.data.surl) {
                    surl = response.data?.data?.surl;
                    let navigatorData: any = global.window.navigator;
                    const tags = getTags(campaignData?.tags);
                    const donation_tags = tags?.join(', ');
                    // if (surl && navigatorData?.userAgentData?.mobile) {
                    if (surl) {
                        let shareData = {
                            title: campaignData?.title,
                            url: surl,
                            text: "Hi there, I am supporting a fundraiser that aims to change the lives of the underprivileged. It would be great if you could support this fundraiser with a contribution and help spread the word at "
                        }
                        if (navigatorData?.canShare(shareData)) {
                            await navigatorData?.share(shareData)
                                .then(() => {
                                    let eventProperties = {
                                        fundraiser_id: campaignData?._id,
                                        channel_type: "share_mobile_1",
                                        utm_source: 'share_mobile',
                                        utm_content: campaignData?.slug,
                                        utm_campaign: 'CampaignShare',
                                        utm_medium: "ShareButton",
                                        utm_term: "ShareOnMobile",
                                        shared_timestamp: new Date(),
                                        shared_by_userid: userData?._id || '',
                                        campaign_tag: donation_tags
                                    }
                                    logAmplitudeEvent('fundraiserShare', eventProperties)
                                    campaignContext.dispatch({
                                        type: "increment-share",
                                    })
                                })
                                .catch(() => console.log('Error sharing'));
                        }
                        else setOpenShareNowModal(true)
                    }
                    else setOpenShareNowModal(true)
                }
                else {
                    setOpenShareNowModal(true)
                }
            }).catch(err => console.log('error in creating surl'));
        }
        else {
            setOpenShareNowModal(true)
        }
    }


    const amountConverted = (isConvertAmount: Boolean, amount: any) => {
        if (isConvertAmount) return convertAmount("INR", amount, currencyExchangeData, currencyList)
        else return amount
    }

    // Set default amount selection function will be used as give now amount
    const defaultSelectionAmount = (currencyCodeData: string) => {
        let donation_options = campaignData?.donation_options;
        if (donation_options) {
            //New donation options format 
            if (donation_options[0]?.options) {
                let currencyBasedOptions = donation_options.filter((item: any) => item?.currency_code === currencyCodeData)
                let defaultOptions = donation_options.filter((item: any) => item.default === true)[0]?.options || []
                let filterOnlyOptions = currencyBasedOptions.length > 0 ? currencyBasedOptions[0]?.options : []
                if (currencyBasedOptions.length === 0) {
                    let convertAmount = amountConverted(true, defaultOptions[0]?.amount)
                    // Unsetting this changes as amount selection would happen on the checkout page 
                    // setSelectedAmount(convertAmount)
                }
                else {
                    let amount: number = amountConverted(false, filterOnlyOptions[0]?.amount);
                    // Unsetting this changes as amount selection would happen on the checkout page 
                    // setSelectedAmount(amount)
                }
            }
            else {
                //Old Donation Options Format
                let currencyBasedOptions = donation_options.filter((item: any) => item?.currency_code === currencyCodeData)
                if (currencyBasedOptions.length === 0) { // If USD is not available do manual conversion
                    let newDonationOptions = [...donation_options]
                    newDonationOptions.map((item: any, idx: number) => {
                        let convertAmount = amountConverted(true, item.amount)
                        item.amount = convertAmount
                        return { ...item }
                    })
                    // Unsetting this changes as amount selection would happen on the checkout page 
                    // setSelectedAmount(newDonationOptions[0].amount)
                }
                else {
                    let amount: number = amountConverted(false, currencyBasedOptions[0]?.amount);
                    // Unsetting this changes as amount selection would happen on the checkout page 
                    // setSelectedAmount(amount)
                }
            }
        }
    }
    //To get the scroll event to hide the sticky give button
    useEffect(() => {
        // const deviceType = getDeviceType()
        setContentVisible(false)
        handleShowSticky(false)
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    // scroll event callback function
    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        let donationPosition = donationRef?.current?.offsetTop
        let aboutGiveSectionPos = aboutGiveRef?.current?.offsetTop
        const inViewport = isScrolledIntoView(getStoryDivPositionRef?.current)
        if (inViewport) {
            setContentVisible(true)
        }
        if (window?.scrollY < Number(donationPosition) - 20) {
            //Hide the sticky "Give Now" button until organiser section shows in Mobile View
            handleShowSticky(false)
        }
        else handleShowSticky(true)
    }

    const canYouDonateMorePlease = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>, amount: number, type: string): void => {
        e.preventDefault();
        let isMonthly = campaignData?.type === DONATION_TYPE_LIST.MONTHLY ? DONATION_TYPE_LIST.MONTHLY : DONATION_TYPE_LIST.ONE_TIME
        initiateDonationFlow(e, amount, isMonthly, type);
    };

    const initiateDonationFlow = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedAmount?: number, monthlySub?: string, type?: string): Promise<void> => {
        e.preventDefault()
        handleShowSticky(false);

        const analyticsData = {
            slug: campaignData?.slug,
            id: campaignData?.nonprofit?._id,
            isDefaultAmount: true,
            price: selectedAmount,
            redirection_checkout_status: false
        }
        Analytics.handleGiveNow(analyticsData);

        handleDonations(e, monthlySub, selectedAmount, type);

        const orderInfo = {
            isDefaultAmount: false,
            redirection_checkout_status: true
        }

        // we are sending default amount as 100 (first arguement)since we are changing the amount in checkout
        const analyticObj = {
            amount: 1,
            orderInfo,
            currencySymbolData,
            campaignData,
            userData,
            donation_currency: currencyCodeData
        }
        triggerAddToCarytAnalytics(analyticObj)
    }
    const handleDonations = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>, monthlySub?: String, selectAmount?: number, type?: string): Promise<void> => {
        setIsLoading(true);
        let formData: DonationFormData;
        let cta_analytics = trackData(type);
        let utmParamsWithSource: Record<string, string> = {};
        let utmParamsInCookie;
        // If URL has utm_source
        if (utmParameters) { 
          if (utmParameters?.utm_source) {
              utmParamsWithSource = { ...utmParameters };
            }
        }
        if (global?.window && global?.window?.getUTMParameters) {
            utmParamsInCookie = global.window.getUTMParameters();
        }  

        if (donationForm !== null) {
            //Calling addToCartEvent for amplitude
            const eventData = {
                campaignData: campaignData,
                utmParameters: utmParameters,
                cta_type: cta_analytics?.cta_type,
                cta_location: cta_analytics?.location,
                location: geoLocation,
                currency: currencyCodeData,
                user: userData,
                variant: SDKVariant
            }
            await logAmplitudeEvent('addToCart', eventData, userIPContext)
            const localReferrer = window?.localStorage.getItem('referrer');
            const referrerLink = parseJSONSafe(localReferrer as string)?.value;
            formData = {
                "campaign_id": identifierId,
                "additional_fields": {
                    // Unsetting this changes as amount selection would happen on the checkout page 
                    "actual_amount": selectAmount === 0 ? undefined : selectAmount,
                    "source_medium" : {
                        ...utmParameters,
                        utm_referal_source: utmParamsInCookie?.utm_referral ? utmParamsInCookie?.utm_referral : 'direct',
                    },
                    'device': cta_analytics?.device,
                    'cta_type': cta_analytics?.cta_type,
                    'cta_location': cta_analytics?.location,
                    "location": geoLocation,
                    "initial_utm_source": utmParamsInCookie?.initial_utm_source ? utmParamsInCookie?.initial_utm_source : 'direct',
                    "referral_domain": referrerLink ? referrerLink : '', //if referrer was stored in local storage, we will get it otherwise our script does not store anything at that point
                },
                // Unsetting this changes as amount selection would happen on the checkout page 
                "amount": selectAmount === 0 ? undefined : selectAmount,
                "currency_code": currencyCodeData,
                "subscription_type": monthlySub ? monthlySub : campaignData?.type,
                "ref": refId,
                "type": shareType
            }
            const homepageBannerABVariant = window?.localStorage.getItem(AMP_HOMEPAGE_BANNER_EXP);
            if (socialProofVariant !== '') {
                formData['additional_fields']['experiments'] = [
                    {
                        name: AMP_SOCIAL_PROOFING_EXP,
                        variant: socialProofVariant
                    }
                ];
            }

            if (homepageBannerABVariant && homepageBannerABVariant !== '') {
                if (formData?.additional_fields?.experiments && formData?.additional_fields?.experiments?.length > 0) {
                    const experimentsArr = [...formData?.additional_fields?.experiments];
                    experimentsArr.push({
                        name: AMP_HOMEPAGE_BANNER_EXP,
                        variant: homepageBannerABVariant
                    })
                    formData['additional_fields']['experiments'] = [...experimentsArr];
                } else {
                    formData['additional_fields']['experiments'] = [
                        {
                            name: AMP_HOMEPAGE_BANNER_EXP,
                            variant: homepageBannerABVariant
                        }
                    ];
                }
            }

            donationPost(API.DONATION, formData)
                .then((res) => {
                    if (res.status === 200) {
                        let orderId = res?.data?.donation?.order_id;
                        const utmParams = new URLSearchParams(utmParameters);
                        let redirectUrl = utmParameters ? `${BASE_URL}/donate/${orderId}?${utmParams}` : `${BASE_URL}/donate/${orderId}`;
                        localStorage.removeItem(AMP_EXP_1);
                        window.location.href = redirectUrl;
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    toastContext?.dispatch({
                        type: "new-toast",
                        value: {
                            open: true,
                            severity: "error",
                            message: err?.response?.data?.error,
                            seconds: 3000,
                        },
                    });

                });
        }
    }

    //Common variant 1 "Give now" button can be used in all other components based on the class provided 
    const renderGiveNowButton = (buttonPosition: string, buttonClass: any, type: string) => {
        return (
            <form name="donate" onSubmit={(e) => canYouDonateMorePlease(e, selectedAmount, type)} ref={donationForm}>
                <input type="hidden" name="campaign_id" value={identifierId} />
                {/* <input type="hidden" name="additional_fields[donation-option][experiment_name]" defaultValue={props && props?.donationOption} /> */}
                {/* <input type="hidden" name="additional_fields[donation-option][selected_option]" value={1} /> */}
                {utmParameters && Object.keys(utmParameters)
                    .map((utmItem: any, index: any) => {
                        return (<input type="hidden" key={`source${index}`}
                            name={`additional_fields[source_medium][${utmItem}]`}
                            value={utmParameters[utmItem]} />)
                    })}
                <input type="hidden" name="currency_code" value={currencyCodeData} />
                {refId ? <input type="hidden" name="ref" value={refId} /> : null}
                {shareType ? <input type="hidden" name="type" value={shareType} /> : null}
                <Button variant="contained" size="small" color="primary"
                    disabled={campaignData?.is_closed || (campaignData?.supercampaign_id)} type="submit"
                    className={buttonPosition === "sticky" ? clsx(buttonClass, classes.smartGiveButton) : buttonClass} >
                    {/* Give Now */}
                    {renderGiveNowButtonText(campaignData)}
                </Button>
            </form>
        )
    }

    //Triggering the donate click button on the payment methods cards 
    const donateClickButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data_type: any) => {
        canYouDonateMorePlease(e, selectedAmount, data_type)
    }

    const donateOtherCampaign = () => {
        history.push(`/${pageRoutes.FUNDRAISERS}`)
    }
    const donationForm = useRef<HTMLFormElement>(null);

    const galleryImage = campaignData && campaignData?.gallery ? checkForImageInGallery(campaignData?.gallery) : ""

    const { causes, nonprofit, title } = campaignData || {};
    const { name } = nonprofit || '';
    const isCausesPresent = causes && causes?.length > 0
    const metaTitle = isCausesPresent ? `Donate for ${causes[0]?.name} and Support ${name} | ${title}` : `Donate to ${name} | ${title}`;
  
    return (
        <React.Fragment>
            <Helmet>
                <title>{`${metaTitle ? metaTitle : ''}`}</title>
                {(campaignData && campaignData?.is_parent === false) ? (<meta name="robots" content="noindex,nofollow" />) : null}
                {(campaignData && campaignData?.is_medical_fundraiser === true) ? (<meta name="robots" content="noindex,nofollow" />) : null}
                {(campaignData && campaignData?.is_no_indexed === true) ? (<meta name="robots" content="noindex,nofollow" />) : null}
                <meta name="title" content={campaignData?.title} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={campaignData?.title} />
                <meta name="twitter:title" content={campaignData?.title} />
                <meta property="og:url" content={campaignData?.permalink} />
                <meta property="twitter:url" content={campaignData?.permalink} />
                <link rel="canonical" href={campaignData?.permalink} />
                <meta name="description" content={campaignData && campaignData?.description?.replace(/<(.|\n)*?>/g, "")} />
                <meta property="og:description" content={campaignData && campaignData?.description?.replace(/<(.|\n)*?>/g, "")} data-react-helmet="true" />
                <meta name="twitter:description" content={campaignData && campaignData?.description?.replace(/<(.|\n)*?>/g, "")} />
                {galleryImage ? <meta property="og:image" content={galleryImage} /> : null}
                {galleryImage ? <meta property="twitter:image" content={galleryImage} /> : null}
            </Helmet>
            {loading ? (<React.Fragment><FundraiserSkeleton /> </React.Fragment>)
                :
                <React.Fragment>
                    {/* Mobile View Content */}
                    {isSmall ?
                        <React.Fragment>
                            {campaignData?.parent_data && <ChildCampaignTitle />}
                            <Container className={classes.wrapper}>
                                {typeof window === "object" && <Navbar campaignDetails={campaignData} slug={campaignData?.slug} />}
                                <Grid className={classes.sectionContainer} id="section-wrap">
                                    {campaignData?.is_authorized === true && !slug.teamSecret && campaignData?.is_medical_fundraiser && !campaignData?.is_giveindia_verified ?
                                        <Grid md={5} xs={12} sm={12} item className={classes.verifyTxt}>This Fundraiser isn't verified. <a href={`/fundraisers/pending-tasks/${slug.identifier}`} style={{ color: "#E7424B", cursor: "pointer" }}>Verify Now</a></Grid>
                                        : null}
                                    <Grid container item className={classes.containerWrapper}>
                                        {campaignData?.is_authorized === true && !slug.teamSecret ?
                                            <Grid md={5} xs={12} sm={12} item className={`${classes.editButtonGrp} ${campaignData?.is_medical_fundraiser && !campaignData?.is_giveindia_verified ? 'no-mt' : ''}`}><EditButton slug={slug} /></Grid>
                                            : null}
                                        <Grid md={8} item className={classes.leftSideContent} id={campaignData?.is_authorized === true && !slug.teamSecret ? 'edit' : 'normal'}>
                                            <Grid item>
                                                <Box className={classes.fundraiserSlider} >
                                                    {slidesList?.length > 0 && (<Box className={`${classes.carouselPanel} "fundraiser_carousel_panel" ${(!campaignData?.parent_data && !isStickyHeader) && "fixed_carousel"}`}>
                                                        <CarouselPanel items={slidesList} path={'fundraiser'} visibleByDefault={true} setAddStickyButtonOnVideo={setAddStickyButtonOnVideo} thumbnailDataForVideo={thumbnailDataForVideo} />
                                                    </Box>)}
                                                </Box>
                                                {slug.teamSecret && <Invitation />}
                                                <Grid className={classes.campaignSection}>
                                                    <Box className={classes.pageHeadingDown} style={{ marginTop: slidesList?.length === 0 ? 80 : 0 }}><Header /></Box>
                                                    <Box className={classes.mobileSmartChoices}>
                                                        {showFundraiserStats ? <FundraiserStats progressVal={progressVal} campaignData={campaignData} /> : null}
                                                        {showMatchingFundsVariant && <Grid>
                                                            {
                                                                matchingFundText && matchingFundText !== '' ?
                                                                    <Box className={classes.mf_info_box}>
                                                                        {/* className={classes.mf_info_text} */}
                                                                        <div dangerouslySetInnerHTML={{ __html: matchingFundText }}></div>
                                                                    </Box> : null
                                                            }
                                                        </Grid>}
                                                        <Snackbar
                                                            open={snackbarOpen}
                                                            autoHideDuration={3000}
                                                            onClose={handleClose}
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                                            TransitionComponent={Slide}
                                                            transitionDuration={500}
                                                        >
                                                            <Alert className={classes.alert}>
                                                                <SocialProofingSnackbar donor={recentDonorData} countries={countriesData} />
                                                            </Alert>
                                                        </Snackbar>
                                                        {campaignData?.is_closed ?
                                                            <Grid ref={donationRef} className={classes.goalMetContainer}>
                                                                <Grid item md={12}><Typography className={classes.goalMetText}>This campaign is not accepting donations anymore.</Typography></Grid>
                                                                <Grid item md={12}>
                                                                    <Button variant="outlined" onClick={donateOtherCampaign} className={classes.shareButton}>Donate to Other Campaigns</Button>
                                                                </Grid>
                                                            </Grid>
                                                            : <Grid id={"donation-btns"} className={classes.donationButtons} ref={donationRef}>
                                                                <Grid item md={12}>{renderGiveNowButton('initial', classes.giveButton, 'initialGiveNow')}</Grid>
                                                                {campaignData?.is_sharing_enabled ? (
                                                                    <Button variant="outlined" onClick={handleShare} className={classes.shareButton}>Share</Button>

                                                                ) : null}
                                                            </Grid>
                                                        }
                                                        {!campaignData?.is_closed && campaignData?.is_sharing_enabled && shareCountState > 1 && <Typography className={classes.totalShareCountText}>{shareCountState} shares! Every share counts.</Typography>}
                                                    </Box>
                                                    {campaignData?.type === DONATION_TYPE_LIST.ONE_TIME && currencyCodeData == "INR" && !campaignData?.is_closed &&
                                                        <DonateViaSection donateClickButton={(e: any, data: any) => donateClickButton(e, data)} />
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>

                            <Container className={classes.wrapper}>
                                <Grid className={classes.sectionContainer}>
                                    <Grid container className={classes.containerWrapper}>
                                        <Grid item className={classes.leftSideContent}>
                                            <Grid item>
                                                <Grid className={classes.campaignSection}>
                                                    <CampaignDetails campaignData={campaignData}
                                                        donors={donors} currencyCode={currencyCodeData}
                                                        renderGiveNow={renderGiveNowButton('initial', classes.supportGiveButton, 'midFold_giveNow')}
                                                        getStoryDivPositionRef={getStoryDivPositionRef}
                                                        contentVisible={contentVisible}
                                                        setDonorModal={setDonorModal}
                                                        donorModal={donorModal}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                            {/* Sticky Give Now Button for smaller device */}
                            {((showSticky && !campaignData?.is_closed) || addStickyButtonOnVideo) &&
                                <React.Fragment>
                                    <Box className={`${addStickyButtonOnVideo ? classes.smartChoiceWrapperVideoBg : classes.smartChoiceWrapperBg} ${classes.smartChoiceWrapper}`}>
                                        {/* {showMatchingFundsVariant && !donorModal &&
                                            <Box className={classes.mf_sticky_info_box}>
                                                <Typography className={classes.mf_sticky_info_text}>
                                                    For every <span className="currency-font-inter">₹</span>100 you donate,
                                                    <span className={classes.mf_info_logoName}>&nbsp;give</span> will donate
                                                    <span className="currency-font-inter"> ₹</span>5 more
                                                </Typography>
                                            </Box>
                                        } */}
                                        <Grid item md={12}>
                                            {renderGiveNowButton('sticky', classes.giveButton, addStickyButtonOnVideo === true ? 'video_popup' : (donorModal === true ? 'donors_list_popup' : 'sticky_giveNow'))}
                                        </Grid>
                                    </Box>
                                </React.Fragment>
                            }
                        </React.Fragment>
                        :
                        // Desktop View Content                         
                        <React.Fragment>
                            {campaignData?.parent_data && <ChildCampaignTitle />}
                            <Container className={classes.wrapper}>
                                {typeof window === "object" && <Navbar campaignDetails={campaignData} slug={campaignData?.slug} />}
                                {campaignData?.is_authorized === true && !slug.teamSecret && campaignData?.is_medical_fundraiser && !campaignData?.is_giveindia_verified ?
                                    <VerificationFooter slug={slug} />
                                    : null}
                                <Grid className={classes.sectionContainer} id="section-wrap">
                                    <Grid container className={classes.containerWrapper}>
                                        {campaignData?.is_authorized === true && !slug.teamSecret ?
                                            <Grid md={5} xs={12} sm={12} item className={classes.editButtonGrp}><EditButton slug={slug} /></Grid>
                                            : null}
                                        <Grid md={12} item className={classes.pageHeadingUp}><Header /></Grid>
                                        <Grid md={8} item className={classes.leftSideContent} id={campaignData?.is_authorized === true && !slug.teamSecret ? 'edit' : 'normal'}>
                                            <Grid item>
                                                {slidesList?.length > 0 && (<Box className={classes.carouselPanel + " fundraiser_carousel_panel"} style={{ marginBottom: 35 }}>
                                                    <CarouselPanel items={slidesList} path={'fundraiser'} visibleByDefault={true}
                                                        thumbnailDataForVideo={thumbnailDataForVideo}
                                                        setAddStickyButtonOnVideo={setAddStickyButtonOnVideo}
                                                        renderGiveNow={renderGiveNowButton('video_carousel', classes.giveButton, 'video_popup')}
                                                    />
                                                </Box>)}
                                                {campaignData?.type === DONATION_TYPE_LIST.ONE_TIME && currencyCodeData == "INR" && !campaignData?.is_closed &&
                                                    <DonateViaSection donateClickButton={(e: any, data: any) => donateClickButton(e, data)} />
                                                }
                                                {slug.teamSecret && <Invitation />}
                                                <Grid className={classes.campaignSection}>
                                                    <CampaignDetails donors={donors} campaignData={campaignData}
                                                        contentVisible={contentVisible}
                                                        getStoryDivPositionRef={getStoryDivPositionRef}
                                                        renderGiveNow={renderGiveNowButton('initial', classes.supportGiveButton, 'midFold_giveNow')} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid md={4} xs={12} sm={12} xl={4} lg={4} item className={classes.rightSideContent}>
                                            <Grid md={12} sm={12} xs={12} xl={12} item className={classes.rightSideCard}>
                                                <Card className={classes.smartChoiceCard}>
                                                    <CardContent className={classes.xsmarginPadding}>
                                                        <Box className={`${classes.smartChoices} ${showFundraiserStats ? '' : 'no-stats'}`}>
                                                            {showFundraiserStats ? <FundraiserStats progressVal={progressVal} campaignData={campaignData} /> : null}
                                                            {showMatchingFundsVariant && <Grid>
                                                                {
                                                                    matchingFundText && matchingFundText !== '' ?
                                                                        <Box className={classes.mf_info_box}>
                                                                            <div dangerouslySetInnerHTML={{ __html: matchingFundText }}></div>
                                                                        </Box> : null
                                                                }
                                                            </Grid>}
                                                            <Grid container justifyContent="space-around" item className={classes.marginAuto}>
                                                                {showVariant1 && <React.Fragment>
                                                                    {campaignData?.is_closed ?
                                                                        <Grid ref={donationRef} className={classes.goalMetContainer}>
                                                                            <Grid item md={12}><Typography className={classes.goalMetText}>This campaign is not accepting donations anymore.</Typography></Grid>
                                                                            <Grid item md={12}>
                                                                                <Button variant="outlined" onClick={donateOtherCampaign} className={classes.shareButton}>Donate to Other Campaigns</Button>
                                                                            </Grid>
                                                                        </Grid> :
                                                                        <>
                                                                            <Grid item md={12} ref={donationRef}>
                                                                                <div className={classes.donationButtons}>{renderGiveNowButton('initial', classes.giveButton, 'initialGiveNow')}</div>
                                                                            </Grid>

                                                                            {campaignData?.is_sharing_enabled ? (
                                                                                <Button variant="outlined" onClick={handleShare} className={classes.shareButton}>Share</Button>
                                                                            ) : null}

                                                                            {!campaignData?.is_closed && campaignData?.is_sharing_enabled && shareCountState > 1 && <Typography className={classes.totalShareCountText}>{shareCountState} shares! Every share counts.</Typography>}
                                                                        </>
                                                                    }
                                                                    <Grid className={classes.dividerBox}><Divider className={classes.divider} /></Grid>
                                                                    <Grid item md={12} className={classes.donorSection}>
                                                                        <VerticalDonorLists donors={donors} currencyCode={currencyCodeData}
                                                                            setDonorModal={setDonorModal}
                                                                            donorModal={donorModal}
                                                                            renderGiveNow={renderGiveNowButton('initial', classes.giveButton, 'donors_list_popup')} />
                                                                    </Grid>
                                                                </React.Fragment>
                                                                }
                                                            </Grid>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                            <Snackbar
                                open={snackbarOpen}
                                autoHideDuration={3000}
                                onClose={handleClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                TransitionComponent={Slide}
                                transitionDuration={500}
                            >
                                <Alert className={classes.alert}>
                                    <SocialProofingSnackbar donor={recentDonorData} countries={countriesData} />
                                </Alert>
                            </Snackbar>
                        </React.Fragment>
                    }
                    <Container className={classes.bottomWrapper} >
                        <AboutGiveSection aboutGiveRef={aboutGiveRef} />
                        <FaqsTab />
                        <Footer showMatchingFundsVariant={showMatchingFundsVariant} currencyCodeData={currencyCodeData} />
                    </Container>
                </React.Fragment>
            }

            <Loader openLoaderData={isLoading} isCustomLoaderStyle={true} />
            <TnCWarningModal isOpen={isTnCModalOpen} setIsOpen={(e: any) => setIsTnCModalOpen(e)} />
            {openShareNowModal && <ShareNowScreen
                open={openShareNowModal}
                handleClose={handleCloseModal}
                slug={campaignData?.slug}
                shareContribution={true}
                shareLink={campaignData?.canonical_url}
                experiment_id={experiment}
                variant={variant}
            />}
        </React.Fragment >
    )
})
export default FundraiserDetails
