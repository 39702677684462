export const apiRoutes = {
    LINK_SURL_NEW: 'links/surl/new',
    NONPROFITS: 'nonprofits',
    UPDATE: 'updates',
    DONATION: 'donations',
}

export const pageRoutes = {
    FUNDRAISERS: 'fundraisers',
    FUNDRAISERS_CREATE_PROGRAM: '/fundraisers/create/program',
    FUNDRAISERS_DASHBOARD: '/fundraisers/dashboard',
    OVERVIEW: 'overview',
    SETTING_BASIC: 'settings/basic',
    PROFILE: '/profile',
    CREATE_PROGRAM_LISTING: '/create/program-listing',
    CREATE_PROGRAM: '/create/program/',
    FUNDRAISER_EDIT: '/fundraisers/edit',
    ABOUT_US:"/aboutus",
    CAREERS:"/careers",
    FUNDRAISERS_CREATE_MEDICAL:"/fundraisers/new/create/medical",
    FUNDRAISERS_CREATE_PROGRAM_LISTING:"/fundraisers/create/program-listing",
    FIND_MORE:"/find-more/",
    NGOS:"/ngos",
    NEWS_LETTER:'/newsletters',
    FUNDRAISERS_CREATE_SELECT:"/fundraisers/create/select"
}