import * as interfaces from "../interfaces";

export const initialState: interfaces.cancelReason = {
    reason : {}
};

export const reducer = (state: any, action: interfaces.action) => {
  switch (action?.type) {
    case "reason":
      return { ...state,  reason: action.value};
    case "reset":
      return initialState;
  }
};

