import React, { useRef, useState, useEffect, useContext, useMemo } from "react";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
// import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { useEditButtonStyles } from "./editButton.style";
import { constantImages as IMG_CONST } from "../../../../imageConstant";
import clsx from 'clsx';
import { pageRoutes as ROUTE } from "../../../../routeConstant";
import { COLORS } from "../../../../assets/variables";
import Context from "../../../../context/Context";


const EditButton = (props: any) => {
  let history = useHistory();
  const slug: any = useParams();
  const classes = useEditButtonStyles();
  const optionsRef = useRef<HTMLButtonElement>(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const context = useContext(Context);
  const campaignContext = context?.Campaign;
  const campaignData = campaignContext?.state?.campaignData;

  const isSuperCampaignFundraiser = useMemo(() => {
    const { supercampaign_id } = campaignData || ''
    return supercampaign_id && supercampaign_id.length > 0
  }, [campaignData])

  const handleClick = () => {
    history.push(`${ROUTE.FUNDRAISER_EDIT}/${slug.identifier}`);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDashboardLinks = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //To get the scroll event to hide the sticky give button
  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  // scroll event callback function
  const onScroll = (e: Event) => {
    setShowDropDown(false)
    setAnchorEl(null);
  }


  return (
    <Box className={classes.buttonGroup}>
      <Button
        variant="contained"
        className={clsx(classes.button, classes.editButton)}
        id="edit-button"
        startIcon={!isSuperCampaignFundraiser?<img src={IMG_CONST.EDIT_ICON} alt="edit-icon"></img>:''}
        onClick={handleClick}
        disabled={isSuperCampaignFundraiser}
      >Edit Fundraiser
      </Button>

      <Button aria-controls="simple-menu" aria-haspopup="true"
        className={classes.button}
        id="options-button"
        endIcon={<KeyboardArrowDown />}
        onClick={handleDashboardLinks}
        ref={optionsRef}
      >Fundraiser Options
      </Button>

      <Menu
        id="fundraiserOptions-menu"
        anchorEl={anchorEl}
        disableScrollLock={true}
        open={Boolean(anchorEl) || showDropDown}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        style={{ marginTop: "0px" }}
        className={classes.menu}
        PaperProps={{
          style: {
            marginTop: 8,
            // boxShadow:'none',
            borderRadius: 8,
            boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.1)',
            border: `1px solid ${COLORS.titleBorderColor}`
          }
        }}
      >
        <MenuItem style={{ width: optionsRef.current?.offsetWidth }}>
          <Link href={`${ROUTE.FUNDRAISERS_DASHBOARD}/${props.slug.identifier}/${ROUTE.OVERVIEW}`}
            className={classes.link}>
            Dashboard
          </Link>
        </MenuItem>
        <MenuItem style={{ width: optionsRef.current?.offsetWidth }}>
          <Link href={`${ROUTE.FUNDRAISERS_DASHBOARD}/${props.slug.identifier}/${ROUTE.SETTING_BASIC}`}
            className={classes.link}>
            Settings
          </Link>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default EditButton;
