import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { Skeleton } from "@material-ui/lab";

const FundraiserSkeleton = () => {

    return (<React.Fragment>
        <React.Fragment>
            <Hidden only={['sm', 'xs']}>
                <Container>
                    <Skeleton variant="rect" width={'100%'} height={80}></Skeleton>
                </Container>
                <Container>
                    <Grid container item><Skeleton variant="text" width={'100%'} height={100}></Skeleton></Grid>
                    <Grid container item spacing={4}>
                        <Grid item md={8} >
                            <Grid style={{ display: 'flex', gap: '20px' }}>
                                <Skeleton variant="text" width={'40%'} height={40}></Skeleton>
                                <Skeleton variant="text" width={'20%'} height={40}></Skeleton>
                            </Grid>
                            <Skeleton variant="rect" width="100%" height={400}
                                style={{ marginTop: 48 }}
                            />
                        </Grid>
                        <Grid item md={4} style={{ marginTop: 88 }}>
                            <Skeleton variant="rect" width={'100%'} height={600} ></Skeleton>
                        </Grid>
                    </Grid>
                </Container>
            </Hidden>
            <Hidden only={['lg', 'md', 'xl']}>
                <Container>
                    <Skeleton variant="rect" width={'100%'} height={60}></Skeleton>
                </Container>
                <Grid style={{ padding: '35px' }}>
                    <Grid item md={12} >
                        <Skeleton variant="rect" width="100%" height={254}
                            style={{ marginTop: 10, marginBottom: 30 }}
                        />
                        <Skeleton variant="text" width={'40%'} height={40}></Skeleton>
                        <Skeleton variant="text" width={'100%'} height={100}></Skeleton>
                        <Skeleton variant="text" width={'100%'} height={40}></Skeleton>
                    </Grid>
                    <Grid item md={12} style={{ marginTop: 10 }}>
                        <Skeleton variant="rect" width={'100%'} height={10} style={{ marginBottom: 10 }}></Skeleton>
                        <Box display={'flex'} justifyContent={'space-between'} gridGap={20}>
                            <Skeleton variant="rect" width={'50%'} height={50} style={{ marginBottom: 20 }}></Skeleton>
                            <Skeleton variant="rect" width={'30%'} height={50} style={{ marginBottom: 20 }}></Skeleton>
                            <Skeleton variant="rect" width={'20%'} height={50} style={{ marginBottom: 20 }}></Skeleton>
                        </Box>
                        <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: 10 }}></Skeleton>
                        <Skeleton variant="rect" width={'100%'} height={50} style={{ marginBottom: 10 }}></Skeleton>
                    </Grid>
                </Grid>
            </Hidden>
        </React.Fragment>
    </React.Fragment>)
}
export default FundraiserSkeleton