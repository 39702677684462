import React, { useContext } from "react";
import Context from "../../context/Context";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const ChildCampaignTitle = () => {
  const context = useContext(Context);
  const campaignContext = context?.Campaign;

  const campaignData = campaignContext?.state?.campaignData;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div id="support-campaign" className={ isSmall ? "child-campaing-title-small" :"child-campaing-title"}>
      <p  className={ isSmall ? "child-campaing-pera-small" :"child-campaing-pera"}>
        <span>This is a Supporting Fundraiser for{" "}</span>
        <a href={`${campaignData?.parent_data?.slug}`}>'{campaignData?.parent_data?.title}'</a>
      </p>
    </div>
  );
};

export default ChildCampaignTitle;
