import { googleClientId } from "../config";
import { get, post } from "./axiosClient";

declare const window: Window &
   typeof globalThis & {
     gapi: any;
     GoogleAuth:any;
     google: any
   }

export const getGoogleContactsFromBackend = async (token:any) => {
    try {
        let requestData = {
            access_token: token
        };
        const res = await get(`contacts/google`,requestData)
        return res.data;
    }catch(err){
        return false;
    }
}

export const generateTokenFromBackend = async (token: any, isContacts: any) => {
    try {
        let requestData = {
            code: token,
        };

        const response: any = await post(`login/generatetoken`, requestData);
        if (isContacts) {
            let newData: any = {};
            response.data.data.contacts.forEach(function (contact: any) {
                if (contact && contact.emailAddresses) {
                    let name;
                    if (contact.names && contact.names.length > 0) {
                        name = contact.names[0].displayName
                    } else {
                        name = contact.emailAddresses[0].value
                    }
                    newData[contact.emailAddresses[0].value] = { name, selected: false }
                }
            });

            // Save the data to redux state
            let arrayData: any[] = [];
            Object.keys(newData).map((d, i) => {
                arrayData[i] = {
                    name: newData[d].name,
                    email: d,
                    selected: true
                };
            });
            return arrayData;
        } else {
            return true;
        }
    } catch (err) {
        return false;
    }
}