import * as interfaces from "../interfaces";

export const initialState: interfaces.dashboard = {
    csvContacts: [],
    importSource: '',
    sendMailFrom:{}
};

export const reducer = (state: any, action: interfaces.action) => {
  switch (action?.type) {
    case "add-csv-data":
      return { ...state,  csvContacts: action.value  };
    case "set-import-source":
        return { ...state,  importSource: action.value  };
    case "set-mail-from":
        return { ...state,  sendMailFrom: action.value  };
    case "reset":
      return initialState;
  }
};
