import * as interfaces from '../context/interfaces';

export const prepareNonProfitDetailsObj = (response:any): interfaces.NonProfitDetails => {
    const nonProfitDetails:interfaces.NonProfitDetails = {
        id:response.data._id,
        name: response.data.name,
        slug: response.data.slug,
        logo: response.data?.image || '',
        video: response.data?.video || '',
        amountRaised:response.data?.statistic?.raised_amount || 0 ,
        supporterCount:response.data?.statistic?.supporter_count || 0,
        description: response.data.description,
        externalProfiles:  response.data?.external_profiles || [],
        address: {
            countryCode:response.data?.address?.country_code || '',
            areaCode:response.data?.address?.area_code || '',
            country:response.data?.address?.country || '',
            street: response.data?.address?.street ? response.data.address.street : "",
            locality: response.data?.address?.locality ? response.data.address.locality : "",
            city: response.data?.address?.city ? response.data.address.city : "",
            region:response.data?.address?.region ? response.data.address.region : "",
            formatted:response.data?.address?.formatted ? response.data.address.formatted : "",
            location:response.data?.address?.location ? response.data.address.location : "",
        },
        causes: (response.data?.causes && response.data.causes.length > 0) ? 
            getCausesFrom(response.data.causes) : [],
        exemptions: (response.data?.exemptions && response.data.exemptions.length > 0) ? 
            getExemptionsFrom(response.data.exemptions) : [],
        taxCertifications: (response.data?.tax_certifications && response.data.tax_certifications.length > 0) ? 
            getTaxCertificationsFrom(response.data.tax_certifications) : [],
        createdAt:response.data.created_at,
        recognitions:(response.data?.recognitions && response.data.recognitions.length > 0) ? 
            getRecognitionsFrom(response.data.recognitions) : [],
        gallery:(response.data?.gallery && response.data.gallery.length > 0) ? 
            getGalleryFrom(response.data.gallery) : [],
        campaigns:(response.data?.campaigns && response.data.campaigns.length > 0) ? 
            response.data.campaigns : [],
        recentUpdates:(response.data?.updates) ? 
            getUpdatesFrom(response.data.updates) : [],
        coverImage:response.data?.cover_image || '',
        panCard: response.data?.pan_card || '',
        fcra: response.data?.fcra || '',
        regNo: response.data?.registration_number || '',
        phoneNo: response.data?.phone || '',
        emailId: response.data?.email || '',
        members: (response.data?.members && response.data.members.length > 0) ?
            getMembersFrom(response.data.members) : [],
        metaTitle: response?.data?.meta_title || '',
        metaDescription: response?.data?.meta_description || '',
        ngoInsights: response?.data?.ngo_insights && response.data.ngo_insights.length > 0 ? response.data.ngo_insights : [],
        isPublished: response?.data?.is_published ? response?.data?.is_published : false,
        status: response?.data?.status ? response?.data?.status : '',
        canAcceptDonationsWithoutFcra: response?.data?.can_accept_donations_without_fcra ? response?.data?.can_accept_donations_without_fcra : false,
    }
    return nonProfitDetails;
}

export const prepareRecentSupportesObj = (response:any): interfaces.NonProfitRecentSupporter[] => {
    let recentSupporters:interfaces.NonProfitRecentSupporter[] = [];
    if(response.data?.supporters && response.data.supporters.length > 0) {
        recentSupporters = response.data.supporters.map((supporter:any) => {
            return {
                amount:supporter.amount,
                tipPercent:supporter.tip_percent,
                isAnonymous:supporter.is_anonymous,
                name:supporter.is_anonymous ? "anonymous" : supporter.user.name,
                currencyCode:supporter.currency_code,
                createdAt:supporter.created_at,
                profilePic: (!supporter.is_anonymous && supporter.user?.profile_pic)  ? 
                    supporter.user?.profile_pic : "",
            }
        })
    }
    return recentSupporters;
}

export const prepareSimilarNGOList = (response:any): interfaces.NonProfitSimilarNgo[] => {
    let similarNgos:interfaces.NonProfitSimilarNgo[] = [];
    if(response?.docs && response.docs.length > 0) {
        similarNgos = response.docs.map((ngo:any) => {
            return {
                id:ngo?._id,
                name:ngo?.name,
                slug: ngo?.slug,
                logo: ngo?.image
            }
        })
    }
    return similarNgos;
}

const getCausesFrom = (causes:any): interfaces.BasicCause[] => {
    return causes.map((cause:any) => {
        return ({
            name:cause.name,
            description: cause.description
        });
    })
}

const getExemptionsFrom = (exemptions:any): interfaces.Exemption[] => {
    return exemptions.map((exemption:any) => {
        return ({
            label: exemption?.label,
            description: exemption?.description
        })
    }).filter((exemption:interfaces.Exemption) => exemption.label != "Gift Aid")
}

const getTaxCertificationsFrom = (taxCertifications:any): interfaces.TaxCertification[] => {
    return taxCertifications.map((taxCertification:any) => {
        return ({
            label: taxCertification?.label,
            description: taxCertification?.description,
            value:taxCertification?.value
        })
    })
}

const getRecognitionsFrom = (recognitions:any): interfaces.NonProfitRecognition[] => {
    return recognitions.map((recognition:any) => {
        return ({
            label: recognition?.label,
            type: recognition?.type,
            url: recognition?.uri,
        })
    })
}

const getGalleryFrom = (gallery: any): interfaces.MultiMedia[] => {
    return gallery.map((image:any) => {
        return ({
            label: image?.label,
            type: image?.media_type,
            uri: image?.uri,
        })
    })
}

const getUpdatesFrom = (updates:any):interfaces.NonProfitUpdate[] => {
    return updates.map((update:any) => {
        return {
        id:update._id,
        campaignId:update.entity_id,
        title:update.title,
        date:update.created_at,
        description:update.description,
        gallery:(update?.gallery && update.gallery.length > 0) ? 
            getGalleryFrom(update.gallery): []
        }
    });
}

const getCampaignsFrom = (campaigns:any): any[] => {
    return campaigns.map((campaign:any) => {
        return ({
            expiryDate: campaign?.expiry_date,
            id: campaign?._id,
            slug: campaign?.slug,
            descriptin: campaign?.description,
            title: campaign?.title,
            isPrimary: campaign?.is_primary,
            donationOptions: (campaign?.donation_options && campaign.donation_options.length > 0) ? 
                getDonationOptionsFrom(campaign.donation_options) : []
        })
    })
}

const getDonationOptionsFrom = (donationOptions:any): interfaces.NonProfitDonationOption[] => {
    return donationOptions.map((opt:any) => {
        return (
            {
                currencyCode:opt?.currency_code,
                amountOptions: (opt?.options && opt.options.length > 0) ? getAmountOptionsFrom(opt.options) : []
            }
        )
    })
}

const getAmountOptionsFrom = (amountOptions:any): interfaces.NonProfitAmount[] => {
    return amountOptions.map((amt:any) => {
        return (
            {
                amount: amt?.amount,
                title: amt?.title
            }
        );
    })
}

const getMembersFrom = (members:any): interfaces.NgoMember[] => {
    return members.map((member:any) => {
        return (
            {
                isPrimaryContact:member.is_primary_contact,
                name:member.name,
                designation:member.designation
            }
        )
    })
}

export const getVideoThumbnailUrl = (videoUrl: string):string => {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = videoUrl.match(regExp);
    let videoId;
    if (match && match[2].length == 11) {
        videoId = match[2];
    }
    if(videoId){
        return `https://i3.ytimg.com/vi/${videoId}/0.jpg`;
    }
    return '';
}