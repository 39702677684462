import * as interfaces from "../interfaces";

export const initialState: interfaces.config = {
  currency: {
    currencyExchangeData: "1",
    currencySymbolData: "₹",
    currencyCodeData: "INR",
    currencyIndexData: "0",
    currencyDefaultData: {},
    currencyList: [],
  },
  defaults: {},
  currencyDefaultData: {},
  causesList: [],
  giveIndiaFaqs: [],
  identifiers: {
    identifierSlug: "",
    identifierId: "",
  },
  showSuccess: {
    showSuccessDialogData: false,
  },
  campaignTabsData: 0,
  campaignTemplates: [],
  homepageData: {},
  pincodes: [],
  countries: [],
  banners: [],
  goodWillBatches:[],
  spotlight:[],
  fundraiserLocations: [],
  nudge:[]
};

export const reducer = (state: any, action: interfaces.action) => {
  switch (action?.type) {
    case "update-currency-data":
      return {
        ...state,
        currency: {
          ...state.currency,
          currencyExchangeData: action.value.currencyExchangeData,
          currencyCodeData: action.value.currencyCodeData,
          currencySymbolData: action.value.currencySymbolData,
          currencyIndexData: action.value.currencyIndexData,
        },
      };
    case "currency-exchange-data":
      return {
        ...state,
        currency: { ...state.currency, currencyExchangeData: action.value },
      };
    case "causes-list":
      return {
        ...state,
        causesList: action.value
      };
    case "currency-code-data":
      return {
        ...state,
        currency: { ...state.currency, currencyCodeData: action.value },
      };
    case "currency-symbol-data":
      return {
        ...state,
        currency: { ...state.currency, currencySymbolData: action.value },
      };
    case "currency-index-data":
      return {
        ...state,
        currency: { ...state.currency, currencyIndexData: action.value },
      };
    case "currency-default-data":
      return {
        ...state,
        currency: { ...state.currency, currencyDefaultData: action.value },
      };
    case "currency-list-data":
      return {
        ...state,
        currency: { ...state.currency, currencyList: action.value },
      };
    case "give-india-faqs-data":
      return { ...state, giveIndiaFaqs: action.value };
    case "identifiers-slug-data":
      return {
        ...state,
        identifiers: { ...state.identifiers, identifierSlug: action.value },
      };
    case "identifiers-id-data":
      return {
        ...state,
        identifiers: { ...state.identifiers, identifierId: action.value },
      };
    case "show-success-dialog-data":
      return {
        ...state,
        showSuccess: {
          ...state.showSuccess,
          showSuccessDialogData: action.value,
        },
      };
    case "campaign-tabs-data":
      return {
        ...state,
        campaignTabsData: action.value,
      };
    case "campaign-template-data":
      return {
        ...state,
        campaignTemplates: action.value,
      };
    case "homepage-data":
      return { ...state, homepageData: action.value };
    case "countries-data":
      return { ...state, countries: action.value };
    case "currency-default-value":
      return {
        ...state,
        currency: {
          ...state.currency,
          currencyDefaultData: action.value
        },
        currencyDefaultData: action.value,
        defaults: action.value
      };
    case "pincodes":
      return {
        ...state,
        pincodes: action.value
      };
    case 'banners':
      return {
        ...state,
        banners: action.value
      };
    case 'spotlight':
      return {
        ...state,
        spotlight: action.value
      };
    case 'goodwill_badges':
      return {
        ...state,
        goodWillBatches: action.value
      };
    case 'fundraiser-locations':
      return {
        ...state,
        fundraiserLocations: action.value
      };
    case 'nudge':
        return { ...state, nudge: action.value };
    case "reset":
      return initialState;
  }
};
