import { Theme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from '../../../../assets/variables';

export const useEditButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            fontWeight: 600,
            marginRight: 12,
            padding: '10px 20px',
            height: '40px',
            width: '100%',
            background: COLORS.white,
            boxShadow: 'none',
            // boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '8px',
            textTransform: 'initial',
            // marginTop: 16,
            fontSize: '1rem',
            minWidth: 'max-content',
            letterSpacing: '0.06em',
            color: COLORS.primary,
            '& img': {
                width: 18
            },
            '& + #options-button': {
                color: COLORS.tertiaryDark,
                border: `1px solid ${COLORS.buttonBorder}`,
                '& :hover': {
                    background: COLORS.white,
                    boxShadow: 'none'
                },
            },
            '&:hover': {
                background: COLORS.white,
                boxShadow: 'none'
            },
            [theme.breakpoints.down('sm')]: {
                marginRight: 0,
            }
        },
        editButton: {
            border: `1px solid ${COLORS.primary}`,

        },
        menu: {
            "& ul": {
                padding: "0",
            },
            "& li": {
                height: 40,
                color: COLORS.tertiaryDark,
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '20px',
                letterSpacing: '0.01em',
                paddingTop: 9,
                whiteSpace: 'nowrap',
                paddingBottom: 12
            },
            '& li.MuiListItem-button:hover': {
                background: 'rgba(253, 242, 226, 0.5) ',
                color: `${COLORS.tertiaryDark} `,
            },

        },
        link: {
            display: "flex",
            alignItems: "center",
            width: '100%',
            textAlign: 'left',
            color: "#222222",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "21px",
            minWidth: 'max-content',
            "&:hover": {
                textDecoration: "none",
            },
            "& svg": {
                marginRight: "10px"
            }
        },
        buttonGroup: {
            display: "flex",
            justifyContent: "center",
            gap: 16,
            marginBottom: 32,
            [theme.breakpoints.down('sm')]: {
                flexFlow: 'wrap',
                padding: 16,
                marginBottom: 0
            },
            '& .MuiButton-contained.Mui-disabled': {
                border: 'none'
            }
        },
    }))