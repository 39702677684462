import axios from 'axios';
import { fetchUtmParamsInCookies, getTags, getUserLocation } from './helpers';
import { AMPLITUDE_KEY, AMP_DEPLOYMENT_KEY, AMP_DEPLOYMENT_KEY_HOMEPAGE_BANNER, AMP_DEPLOYMENT_KEY_SOCIAL_PROOF_V2, AMP_EXP_1, AMP_HOMEPAGE_BANNER_EXP, AMP_SOCIAL_PROOFING_EXP } from '../config';
import * as amplitude from '@amplitude/analytics-browser';
import { v4 as uuidv4 } from 'uuid';
import DBStorage from './DBStorage';
import { Experiment } from '@amplitude/experiment-js-client';
import { UserIPState } from 'context/interfaces';

const { info } = console;


const EVENT_TYPE: any = {
    fundraiserPage: 'CF_LANDINGPAGE',
    addToCart: 'CF_LANDINGPAGE_CTA',
    homePagebannerClicked: 'CF_HOMEPAGE_BANNER_CLICKED',
    spotlightBannerClicked: 'CF_SPOTLIGHT_BANNER_CLICKED',
    homePageFeaturedFundrClicked: 'CF_HOMEPAGE_FEATURED_FUNDRAISER_CLICKED',
    spotlightFeaturedFundrClicked: 'CF_SPOTLIGHT_FEATURED_CLICKED',
    monthlyMissionClicked: 'CF_HOMEPAGE_MONTHLY_MISSION_CLICKED',
    donate2FundraiserClicked: "CF_HOMEPAGE_DONATE_TO_FUNDRAISER_CLICKED",
    subcribe_clicked: 'CF_HOMEPAGE_SUBSCRIBE_CLICKED',
    fundraiserShare: 'CF_FR_PAGE_SHARE_CLICKED',
    fundraiserShareMidScroll: 'CF_FR_PAGE_SHARE_MID_SCROLL_CLICKED',
    landing_on_homePage: 'CF_HOMEPAGE',
    start_Fundraiser_Clicked_Menu: 'CF_STARTFUNDRAISER_CTA',
    start_Raising_Funds_Clicked: 'CF_STARTFUNDRAISER_GIVE_CTA',
    homepage_Enroll_NGO_Clicked: 'CF_HOMEPAGE_ENROLL_NGO',
    landing_on_choose_purpose_Page: 'CF_CHOOSEPURPOSE',
    ngo_startedClicked: 'CF_NGO_GETSTARTED_CTA',
    medical_getStartedClicked: 'CF_MEDICAL_GETSTARTED_CTA',
    searchFundraisers: 'CF_SEARCHFUNDRAISERS', //click on search icon on homepage
    landing_on_featured_campaign: 'CF_FEATUREDCAMPAIGN',
    search_on_fundraiserListingPage: 'CF_SEARCH_FR_LISTINGPAGE',  //fundraiser homepage search on modal 
    search_on_createPage: "CF_SEARCH_ON_CREATEPAGE",  //search string on create fr page
    viewDetails_clicked: 'CF_VIEWDETAILS', //click on view details button on non-profits
    choose_NonProfit: 'CF_CHOOSENONPROFIT', //click on select nonprofit button cta
    choose_Campaign: 'CF_CHOOSECAMPAIGN', //click on select campaign button cta
    search_to_FindMore: 'CF_SEARCHTO_FINDMORE',
    showMore_clicked: 'CF_SHOWMORE',
    landing_fundraiser_homePage: 'CF_FUNDRAISERS_HOMEPAGE',
    browse_fundraiser_clicked: 'CF_BROWSEFUNDRAISERS_CTA',
    create_fundraiser_supporting: 'CF_CREATEFUNDRAISER_SUPPORTING',
    landing_on_create_fundraiser_form: 'CF_CREATEFUNDRAISER_FORM',
    createFundraiserLoadLoginPopup: "CF_LOGIN_CREATEFUNDRAISER_LOADFORM",
    createFundraiser_login_cta: "CF_LOGIN_CREATEFUNDRAISER_CTA", //log in event on create fundraiser page 
    create_fundraiser_cta: 'CF_CREATEFUNDRAISER_CTA',
    medical_fundraiser_cta: "CF_MEDICAL_CREATEFUNDRAISER_CTA",
    medical_fr_verify_complete_cta: "CF_MEDICAL_VERIFICATION_COMPLETE_CTA",
    change_cause_from_dropdown: 'CF_CAUSE_CHANGED',
    cause_choose: 'CF_CAUSE_CHOOSE',
    most_funded_NGO: 'CF_Most_funded_NGO_select',
    most_recent_NGO: 'CF_Most_recent_NGO_click',
    most_popular_NGO: 'CF_most_Popular_NGO_click',
    most_funded_Campaign: 'CF_Most_funded_campaign_select',
    most_recent_Campaign: 'CF_Most_recent_campaign_click',
    most_popular_Campaign: 'CF_most_Popular_campaign_click',
    thumbnailClicked: 'CF_THUMBNAIL_CLICKED',// Thumbnail Video popup event on slider
    homepageTappedGiveLogo: "CF_HOMEPAGE_TAPPED_GIVE_LOGO",
    landingpageTappedGiveLogo: "CF_LANDINGPAGE_TAPPED_GIVE_LOGO",
    frListingPageView: "FR_LISTING_PAGEVIEW",//Fundraiser listing page events 
    frListing_FundraiserClicked: "FR_LISTING_LISTED_FUNDRAISER_CLICKED",
    frmonthlyFundraiseClicked: "FR_LISTING_MONTHLY_FUNDRAISER_CLICKED",
    spotlightMonthlyClicked: "CF_SPOTLIGHT_MONTHLY_CLICKED",
    frListingSearch: "FR_LISTING_SEARCH",
    frListingFilterCause: "FR_LISTING_FILTER_CAUSE",
    frListingFilterNGOSearch: "FR_LISTING_FILTER_NGO_SEARCH",
    frListingFilterOptions: "FR_LISTING_FILTER_OPTIONS",
    frListing_FundraiserCTAClicked: "FR_LISTING_LISTED_FUNDRAISER_DONATE_CTA",
    secondary_banner_click: "CF_SECONDARY_BANNER_CLICK",
    frListingBlogClicked: "FR_LISTING_BLOG_CLICKED",
    frListingExploreCause: "FR_LISTING_EXPLORE_CAUSE",
    footerSocialCauseClick: "CF_FOOTER_SOCIALCAUSE_CLICKED",
    combinedMonthlyLpPageview: "COMBINED_MONTHLY_LP_PAGEVIEW",
    clickedMonthlyFundraiserCombinedLp: "CLICKED_MONTHLY_FUNDRAISER_COMBINED_LP",
    // frListingFilterLocation:"FR_LISTING_FILTER_ADDITIONAL_LOCATION" //Not required for P0
    // Profile Page Events
    profilePageMonthlyMissionClicked: "CF_PROFILE_PAGE_MONTHLY_MISSION_CLICKED",
    profilePageEploreFundraiserClicked: "CF_PROFILE_PAGE_EXPLORE_FUNDRAISER_CLICKED",
    profileMonthlyBannerClicked: "CF_PROFILE_MONTHLY_BANNER_CLICKED",
    profileGiveMoreClicked: "CF_PROFILE_GIVE_MORE_CLICKED",
    profileFundraiserThumbnailClicked: "CF_PROFILE_FUNDRAISER_THUMBNAIL_CLICKED",
    profileFundraiserTitleClicked: "CF_PROFILE_FUNDRAISER_TITLE_CLICKED",
    profilePage: "CF_PROFILE_PAGE",
    profileMenuTabClicked: "CF_PROFILE_MENU_TAB_CLICKED",
    /*NGO Profile Page Start*/
    ngoPageView: "NGO_PAGEVIEW",
    ngoDonateCtaMain: "NGO_DONATE_CTA_MAIN",
    ngoDonateCtaCard1: "NGO_DONATE_CTA_CARD1",
    ngoDonateCtaCard2: "NGO_DONATE_CTA_CARD2",
    ngoCreateFundraiser: "NGO_CREATE_FUNDRAISER",
    ngoPlayVideo: "NGO_PLAYVIDEO",
    ngoImportantLink: "NGO_IMPORTANT_LINK",
    ngoFRListDonatCTA: "NGO_FRLIST_DONATE_CTA",
    ngoSimilarFRDonateCTA: "NGO_SIMILARFR_DONATE_CTA",
    ngoFRListFundraiserClicked: "NGO_FRLIST_FUNDRAISER_CLICKED",
    ngoSimilarFRClicked: "NGO_SIMILAR_FR_CLICKED",
    ngoCreateSupportingFundraiser: "NGO_FRLIST_CREATE_FUNDRAISER",
    /*NGO Profile Page End*/
    givestarClickedQuickAction: "GIVESTAR_CLICKED_QUICK_ACTION",
    spotlightPageView: 'CF_SPOTLIGHT_PAGEVIEW',
    /*NGO Profile Page End*/
    /*NGO Onboarding Events*/
    ngoOnboardingPageView:'NGO_ONBOARDING_PAGEVIEWED',
    ngoOnboardingSignUp:'NGO_ONBOARDING_SIGNUP',
    ngoOnboardingFillForm:'NGO_ONBOARDING_FILLFORM',
    ngoOnboardingSubmitForm:'NGO_ONBOARDING_SUBMITFORM',
    ngoOnboardingResources:'NGO_ONBOARDING_RESOURCES',
    ngoOnboardingEvents:'NGO_ONBOARDING_EVENTS',
    ngoOnboardingTestimonial:'NGO_ONBOARDING_TESTIMONIAL',
    ngoOnboardingEventsSection:'NGO_ONBOARDING_EVENTSSECTION',
    ngoOnboardingTestimonialSection:'NGO_ONBOARDING_TESTIMONIAL',
    ngoOnboardingResourceSection:'NGO_ONBOARDING_RESOUCESSECTION',

    /*Cancellation Flow Events Start*/

    clicked_manage_subscription_cancelation_flow:'CLICKED_MANAGE_SUBSCRIPTION_CANCELATION_FLOW',
    selects_cancelation_reason:'SELECTS_CANCELATION_REASON',
    clicked_continue_cta_cancelation_flow:'CLICKED_CONTINUE_CTA_CANCELATION_FLOW',
    clicked_primary_cta_cancelation_flow:'CLICKED_PRIMARY_CTA_CANCELATION_FLOW',
    clicked_secondary_button_cancelation_flow:'CLICKED_SECONDARY_BUTTON_CANCELATION_FLOW',
    explore_fundraiser_from_cancelation_flow:'EXPLORE_FUNDRAISER_FROM_CANCELATION_FLOW',
    
    clicked_secondary_button_cancelation_flow_post_confirmation:"CLICKED_SECONDARY_BUTTON_CANCELATION_FLOW_POST_CONFIRMATION",

    subscription_canceled:'SUBSCRIPTION_CANCELED',
    subscription_paused:'SUBSCRIPTION_PAUSED',
    subscription_downgraded:'SUBSCRIPTION_DOWNGRADED',
    cfResumeSubscription: 'CF_RESUME_SUBSCRIPTION', 

    /*Cancellation Flow Events End*/
    
    loginPopupOpened: 'LOGIN_POPUP_OPENED',
    homePageNotification:"CF_HOMEPAGE_NOTIFICATION",
    locationBasedPageviewed:"LOCATION_BASED_PAGEVIEWED",
    locationBasedClicked:"LOCATION_BASED_CLICKED"
    
};

export const initAmplitude = (user: any) => {
    let device_id = localStorage.getItem('amplitude_device_id');
    const uuid = device_id ? device_id : uuidv4();
    if (typeof window !== 'undefined') {
        if (user && user._id) {
            amplitude.init(AMPLITUDE_KEY, user.email, {
                deviceId: device_id ? device_id : uuid,
                transport: "beacon",
                flushIntervalMillis: 0,
                flushQueueSize: 1,
                defaultTracking: true,
            });
        } else if (device_id) {
            amplitude.init(AMPLITUDE_KEY, "", {
                deviceId: device_id,
                transport: "beacon",
                flushIntervalMillis: 0,
                flushQueueSize: 1,
                defaultTracking: true,
            });
        } else {
            amplitude.init(AMPLITUDE_KEY, "", {
                deviceId: uuid,
                transport: "beacon",
                flushIntervalMillis: 0,
                flushQueueSize: 1,
                defaultTracking: true,
            });
            localStorage.setItem('amplitude_device_id', uuid)
        }
    }
};

export const initExperiment = async (experiment_key: string, deployment_key: string) => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem(experiment_key);
        let experiment = Experiment.initializeWithAmplitudeAnalytics(deployment_key);
        await experiment.start();
        const variant = experiment.variant(experiment_key);
        if (experiment_key === AMP_EXP_1) {
            DBStorage.set('matchingFundText', variant?.payload?.text)
        }
        DBStorage.set(experiment_key, variant.value)
        if (variant.value?.startsWith('variant')) {
            // Flag is on
            return { experiment: experiment_key, variant: variant.value }
        } else {
            // Flag is off
            return false
        }
    }

}

export const setUserIdData = (userId: string): void => {
    if (typeof window !== 'undefined') {
        // const amplitude = require('amplitude-js');
        amplitude?.setUserId(userId);
    }
};


//creating function to load ip address from the API
const getIP = async () => {
    try {
        const res = await axios.get('https://geolocation-db.com/json/')
        return res?.data?.IPv4
    }
    catch (e) { }
}

export const logAmplitudeEvent = async (event: string, data: any | object = {}, geoLocationData?: UserIPState) => {
    if (typeof window !== 'undefined') {
        // const amplitude = require('amplitude-js');
        const tags = getTags(data?.campaignData?.tags);
        let locationData: any;
        let user_ip: any;
        if (geoLocationData?.IPv4) {
            locationData = {
                type: 'point', coordinates: [
                    geoLocationData?.longitude,
                    geoLocationData?.latitude
                ]
            };
            user_ip = (geoLocationData?.IPv4) || '';
        } else {
            user_ip = '';
        }

        const user_info = {
            user_id: data?.user ? data?.user?._id : undefined,
            em: data?.user?.email ? data?.user?.email : undefined,
            ph: data?.user?.phone ? data?.user?.phone : undefined,
            fn: data?.user?.name ? data?.user?.name : undefined,
            zp: data?.user?.address?.area_code ? data?.user?.address.area_code : undefined,
            st: data?.user?.address?.region ? data?.user?.address?.region : undefined,
            ct: data?.user?.address?.city ? data?.user?.address?.city : undefined,
            country: data?.user?.address?.country_code ? data?.user?.address?.country_code : undefined,
            is_guest: data?.user?.is_guest ? data?.user?.is_guest : undefined,
        };

        if (event === 'fundraiserPage' || event === 'addToCart' || event === 'thumbnailClicked') {
            if (geoLocationData?.IPv4) {
                locationData = {
                    type: 'point', coordinates: [
                        geoLocationData?.longitude,
                        geoLocationData?.latitude
                    ]
                };
                user_ip = (geoLocationData?.IPv4) || '';
            } else {
                
                user_ip = '';
            }
        }

        let utmParamsInCookie: any;
        let utmParamsWithSource: any;
        if (data?.utmParameters) {
            if (data?.utmParameters) {
                if (data?.utmParameters?.utm_source) {
                    utmParamsWithSource = {
                        ...data?.utmParameters
                    };
                } 
            }
        }
        if (event === 'fundraiserPage' || event === 'addToCart' || event === "thumbnailClicked" ) {
            const donation_tags = tags.length > 0 ? tags?.join(', ') : '';
            const eventProperties = {
                // Few default values will be tracked from amplitude 
                "landing_page_link": data?.landing_page_link || '',
                "timestamp": new Date(),
                "location_lng": locationData && locationData?.coordinates && locationData?.coordinates.length > 0 ? locationData?.coordinates[0] : '',
                "location_lat": locationData && locationData?.coordinates && locationData?.coordinates.length > 0 ? locationData?.coordinates[1] : '',
                "user_id": user_info?.user_id || '',
                "ip_address": user_ip,
                "city": user_info?.ct || '',
                "region": user_info?.st || '',
                "pincode": user_info?.zp || '',
                "country": user_info?.country || '',
                "currency": data?.currency,
                "is_guest": user_info?.is_guest || '',
                "fundraiser_id": data?.campaignData?._id,
                "fundraiser_title": data?.campaignData?.title,
                "fundraiser_short_title": data?.campaignData?.short_title,
                "is_monthly_giving": data?.campaignData?.is_monthly_giving,
                "fundraiser_tags": donation_tags || '',
                "fundraiser_type": data?.campaignData?.donation_type, // campaign Type (classic,reward, etc..)
                "nonprofit": data?.campaignData?.nonprofit?.name || '', //Non profit name
                "nonprofit_id": data?.campaignData?.nonprofit?._id || '', // Non profit id
                "organiser_id": data?.campaignData?.creator?.id || '',
                "organiser_name": data?.campaignData?.creator?.name || '',
                "subscription_type": data?.campaignData?.type || '', //One time or Monthly
                "is_supporting_fundraiser": data?.campaignData?.parent_data && data?.campaignData?.parent_data?._id ? true : false,
                "parent_fundraiser_id": data?.campaignData?.parent_data ? data?.campaignData?.parent_data?._id : '',
                "cta_location": data?.cta_location || '',
                "cta_type": data?.cta_type || '',
                ...data?.utmParameters || utmParamsInCookie,
                // removed fallback of direct
                "utm_source": data?.utmParameters && data?.utmParameters?.utm_source,
                "utm_referal_source": fetchUtmParamsInCookies() ? fetchUtmParamsInCookies() : 'direct',
            }
            // info(`${EVENT_TYPE[event]} is on track currently..`);
            const result = await amplitude.track(EVENT_TYPE[event], eventProperties).promise;
            return result;
        }
        else {
            let customData = {
                ...data,
                "ip_address": user_ip,
                "city": user_info?.ct || '',
                "region": user_info?.st || '',
                "pincode": user_info?.zp || '',
                "country": user_info?.country || '',
            }
            // info(`${EVENT_TYPE[event]} is on track currently..`, customData);
            const result = await amplitude.track(EVENT_TYPE[event], customData).promise;
            return result;
        }
    }
}
