import React, { useEffect, useContext, useState, } from "react";
import { Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { amountConverterHelper, getShortCurrencyString } from "../../../utils/helpers";
import { convertCurrency } from "../../../utils/dataModifiers";
import Context from "../../../context/Context";
import LinearProgress from '@material-ui/core/LinearProgress';
import { COLORS } from '../../../assets/variables';
import loadable from "@loadable/component";
const CurrencySign = loadable(() => import("../../common/Cards/CurrencySign"));

const useFundraiserStatsStyles = makeStyles((theme: Theme) =>
    createStyles({
        barRoot: {
            height: 8,
            borderRadius: 8,
        },
        colorPrimary: {
            backgroundColor: `${COLORS.titleBorderColor} `,
        },
        inProgressBar: {
            backgroundColor: `${COLORS.primaryProgressBar} `, //yellow
        },
        completedBar: {
            backgroundColor: `${COLORS.primaryGreen} ` //green
        },
        amountRaised: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '1.25rem', //20px
            letterSpacing: '-0.02em',
            color: COLORS.secondaryDark,
            marginTop: 16,
            padding: 0,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',  //24px
                marginTop:10
            }
        },
        raisedText: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '0.75rem', //12px
            lineHeight: '20px',
            color: COLORS.secondaryDark,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem', //14px
            },
            '& .shortTerm': {
                paddingLeft: 5
            }
        },
    }))

const FundraiserStats = React.memo((props: any) => {
    const { progressVal, campaignData } = props
    const classes = useFundraiserStatsStyles();
    const context = useContext(Context);
    const config = context?.Config;
    const { currencyList, currencyCodeData, currencyExchangeData, } = config?.state?.currency;
    const [currencyCode, setCurrencyCode] = useState<any>(null);
    const [exchangeRate, setExhangeRate] = useState(1);

    useEffect(() => {
        setCurrencyCode(currencyCodeData);
        setExhangeRate(currencyExchangeData);
    }, [currencyCodeData, currencyExchangeData]);

    return (
        <React.Fragment>
            <LinearProgress variant="determinate" value={progressVal}
                classes={{
                    root: classes.barRoot,
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: campaignData?.is_closed ? classes.completedBar : classes.inProgressBar,
                }}
                style={{ opacity: campaignData?.is_closed ? 0.5 : 1 }}
            />
            <Grid container spacing={2} justifyContent="space-between" style={{
                opacity: campaignData?.is_closed ? 0.5 : 1
            }}>
                <Grid item md={6} lg={6} xl={6} sm={6} xs={5}>
                    <Typography className={classes.amountRaised}>
                        <CurrencySign code={currencyCode} />
                        {convertCurrency(amountConverterHelper("INR", campaignData?.statistic?.raised_amount, exchangeRate, currencyList), currencyCode)}
                    </Typography>
                    <Typography className={classes.raisedText}>Raised of
                        <span className="shortTerm"><CurrencySign code={currencyCode} /></span>
                        {getShortCurrencyString(campaignData?.target_amount, currencyCode, currencyList, exchangeRate)} goal
                    </Typography>
                </Grid>
                <Grid item md={3} lg={3} xl={3} sm={3} xs={4}>
                    <Typography className={classes.amountRaised}>{campaignData?.statistic.supporter_count}</Typography>
                    <Typography className={classes.raisedText}>Donations</Typography>
                </Grid>
                <Grid item md={3} lg={3} xl={3} sm={3} xs={3}>
                    <Typography className={classes.amountRaised}>{campaignData?.days_remaining}</Typography>
                    <Typography className={classes.raisedText}>Days Left</Typography>
                </Grid>
            </Grid>
        </React.Fragment>)

})

export default FundraiserStats