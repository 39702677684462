import React from "react";
import { Snackbar, makeStyles, Theme } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

interface Props {
  open: boolean;
  severity: Severity;
  message: String;
  messages?: Array<string>;
  seconds: number;
  handleClose: Function;
}

// Since attribute severity can only have 5 values,
type Severity = "error" | "success" | "info" | "warning" | undefined;

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme: Theme) => ({
  snackbarRoot : {
    zIndex : 9999999999999
  }
}))

const ToastMessage: React.FunctionComponent<Props> = ({
  open,
  severity,
  message,
  messages,
  seconds,
  handleClose,
}) => {
  const handleCloseButton = () => {
    handleClose();
  };

  const classes = useStyles()
  return messages?.length ? (
    <div>
      {messages?.map((message: any) => (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={seconds}
            onClose={handleCloseButton}
            classes={{ root: classes?.snackbarRoot }}
          >
            <Alert onClose={handleCloseButton} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
        </div>
      ))}
    </div>
  ) : (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{
          height: "auto",
          lineHeight: "28px",
          padding: 24,
          whiteSpace: "pre-line",
        }}
        classes={{ root: classes?.snackbarRoot }}
        open={open}
        autoHideDuration={seconds}        
        onClose={handleCloseButton}
      >
        <Alert onClose={handleCloseButton} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ToastMessage;
