import React from 'react';
import DeviceInfoContext from './deviceInfoContext';
interface RenderByDeviceTypeProps { 
    desktop: any,
    mobile: any,
    tablet:any
  }

class RenderByDeviceType extends React.Component<RenderByDeviceTypeProps> {

    render() {

        const desktopView = this.props.desktop;
        const mobileView = this.props.mobile;
        const tabletView = this.props.tablet || this.props.desktop;

        return (
            <React.Fragment>
                <DeviceInfoContext.Consumer>
                    {(deviceInfo) => {

                        if (deviceInfo.device === 'mobile') {
                            return (mobileView);
                        }

                        if (deviceInfo.device === 'tablet') {
                            return (tabletView);
                        }

                        return (desktopView);
                    }}
                </DeviceInfoContext.Consumer>
            </React.Fragment>
        );
    }
}



export default RenderByDeviceType;