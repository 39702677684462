import { Theme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from '../../../assets/variables';

export const useFundraiserDetailsPageStyles: ReturnType<typeof makeStyles> = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        alert: {
            '&.MuiAlert-standardSuccess .MuiAlert-icon': {
                display: 'none', // Hide the icon
              },
              '&.MuiAlert-standardSuccess': {
                backgroundColor: 'transparent', // Remove the background color
              },
        },
        verifyTxt: {
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 600,
            background: '#F8F1F1',
            padding: 12,
            color: '#222222',
            marginTop: 70
        },
        sectionContainer: {
            width: '100%',
            margin: '0 auto',
            padding: '16px 0px 0px',
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            }
        },
        containerWrapper: {
            width: '100%',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                padding: 0,
                margin: 0,
                display:'unset'
            }
        },
        wrapper: {
            margin: '0 auto',
            width: '100%',
            maxWidth: '1216px',
            boxSizing: 'content-box',
            padding: '0px 16px',
            [theme.breakpoints.down('sm')]: {
                boxSizing: 'border-box',
                padding: 0
            }
        },
        bottomWrapper: {
            margin: '0 auto',
            marginTop: 60,
            padding: 0,
            maxWidth: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: 0
            }
        },
        leftSideContent: {
            maxWidth: '65% !important',
            boxSizing: 'border-box',
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                padding: '0px',
                maxWidth: '100% !important'
            }
        },
        rightSideContent: {
            maxWidth: '32% !important',
            boxSizing: 'border-box',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
                padding: 0,
            },
        },
        rightSideCard: {
            position: 'sticky',
            top: '1rem',
            minWidth: 384,
            background: COLORS.white,
            boxSizing: 'border-box',
            border: `1px solid ${COLORS.dividerColor}`,
            boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
            borderRadius: 16,
            marginBottom: 70
        },

        share_container: {
            marginTop: 12,
            gap: 8,
            flexWrap: 'nowrap',
            justifyContent: 'space-evenly'
        },
        shareButton_whatsapp: {
            color: '#25D366',
            border: '1px solid #DEDEDE',
            height: 48,
            padding: '12px',
            lineHeight: '48px',
            width: '100%',
            minWidth: 'max-content',
            background: '#FFF',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            borderRadius: 8,
            boxSizing: 'border-box',
            textTransform: 'inherit'
        },
        shareButton_whatsapp_desktop: {
            color: '#25D366',
            border: '1px solid #25D366',
            height: 48,
            padding: '12px 40px',
            width: '100%',
            background: '#FFF',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            borderRadius: 8,
            boxSizing: 'border-box',
            textTransform: 'inherit'
        },
        shareButtonIcon: {
            height: 48,
            width: '100%',
            minWidth: 56,
            boxSizing: 'border-box',
            padding: '12px 16px',
            borderRadius: '8px !important',
            border: '1px solid #DEDEDE',
            background: COLORS.white,
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            color: '#000000'
        },
        shareButton: {
            // marginRight: 12, commenting temporarily for the whatsapp A/B testing
            padding: '10px 20px',
            height: '44px',
            width: '100%',
            background: COLORS.white,
            border: `1px solid ${COLORS.buttonBorder}`,
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '8px',
            textTransform: 'initial',
            marginTop: 16,
            fontSize: '1rem',
            minWidth: 'max-content',
            '&:hover': {
                color: COLORS.tertiaryDark,
                background: COLORS.white
            },
            '&:focus': {
                backgroundColor: COLORS.textFocus,
                border: `1px solid ${COLORS.secondaryOutline}`,
                color: COLORS.secondaryDark
            },
            [theme.breakpoints.up('md')]: {
                padding: '12px 20px',
                height: '48px',
                fontSize: '1rem'
            },
        },

        giveButton: {
            width: '100%',
            padding: '10px 20px',
            height: '44px',
            color: COLORS.white,
            boxShadow: 'none',
            borderRadius: '8px',
            background: COLORS.primary,
            fontFamily: 'Poppins',
            fontSize: '1rem',
            fontStyle: 'normal',
            textAlign: 'center',
            textTransform: 'initial',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: COLORS.buttonHoverColor,
            },
            '&:focus': {
                backgroundColor: COLORS.buttonFocusColor
            },
            [theme.breakpoints.down('sm')]: {
                padding: '12px 20px',
                height: '48px',
            }
        },
        smartChoices: {
            padding: '32px',
            [theme.breakpoints.down('sm')]: {
                width: '95%',
                margin: '0 auto',
                marginTop: 20
            },
            '&.no-stats': {
                paddingTop: '0px'
            }
        },
        mobileSmartChoices: {
            width: '100%',
        },
        smartChoiceWrapperBg: {
            background: 'white'

        },
        smartChoiceWrapperVideoBg: {
            background: 'rgba(0, 0, 0, 0.6) !important'
        },
        smartChoiceWrapper: {
            position: 'fixed',
            zIndex: 10001,
            bottom: 0,
            width: '100%',
            background: COLORS.white,
            padding: '12px 16px',
            boxSizing: 'border-box',
            [theme.breakpoints.down('sm')]: {
                marginTop: 20,
                animation: `0.5s $slideUp`,
            },
            boxShadow: '0px -4px 10px 0px rgba(0, 0, 0, 0.16)',
        },
        smartChoiceGiveButtonContainer: {
            padding: '10px 16px 20px 16px',
        },
        smartGiveButton: {
            marginTop: 0
        },
        '@keyframes slideUp': {
            "0%": {
                bottom: -20,
                opacity: 0.8
            },
            "100%": {
                bottom: 0,
                opacity: 1,
            }
        },
        pageHeadingUp: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
            [theme.breakpoints.up('xs')]: {
                display: 'none',
            },
            [theme.breakpoints.up('md')]: {
                display: 'inherit'
            },
        },
        pageHeadingDown: {
            [theme.breakpoints.down('sm')]: {
                display: 'inherit',
            },
            [theme.breakpoints.up('md')]: {
                display: 'none'
            },
        },
        carouselPanel: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: '20px !important'
            }
        },
        campaignSection: {
            [theme.breakpoints.down('sm')]: {
                padding: '0px 16px 30px',
                boxSizing: 'border-box'
            }
        },
        supportGiveButton: {
            padding: '10px 16px',
            minWidth: '150px',
            height: '44px',
            color: COLORS.white,
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '8px',
            background: COLORS.primary,
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            textAlign: 'center',
            textTransform: 'initial',
            '&:hover': {
                backgroundColor: COLORS.buttonHoverColor,
                boxShadow: 'none'
            },
            '@media (max-width:767px)': {
                height: 48,
                marginRight: 0,
                padding: '10px 20px',
                marginBottom: 12,
                width: '100% '
            },
        },
        dividerBox: {
            width: '100%',
            marginTop: 24,
            marginBottom: 0
        },
        divider: { background: COLORS.dividerColor },
        smartChoiceCard: {
            background: 'transparent',
            boxShadow: 'none'
        },
        xsmarginPadding: {
            padding: '0px !important',
            margin: 0
        },
        marginTop10: {
            marginTop: 10
        },
        donationButtons: {
            marginTop: 32,
            [theme.breakpoints.down('sm')]: {
                marginTop: 12
            }
        },
        goalMetContainer: {
            marginBottom: 0
        },
        marginAuto: {
            margin: '0px auto'
        },
        goalMetText: {
            color: COLORS.primaryGreen,
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '20px',
            textAlign: 'center',
            marginTop: 16,
            marginBottom: 32,
            [theme.breakpoints.down('sm')]: {
                marginTop: 16,
                marginBottom: 40,
            }
        },
        donorSection: {
            marginTop: 0
        },
        editButtonGrp: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '74px',
                '&.no-mt': {
                    marginTop: 0
                }
            }
        },
        totalShareCountText: {
            paddingTop: 8,
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '20px',
            color: COLORS.tertiaryDark,
            textAlign: 'center'
        },
        fundraiserSlider: {
            '& .slider-img-wrapper': {
                maxHeight: 200,
                overflow: 'hidden',
                '& img': {
                    height: 200
                }
            },
        },
        mf_info_box: {
            background: '#EAF2FD',
            borderRadius: 8,
            padding: '8px 16px',
            marginTop: 24,
            marginBottom: -8,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 24,
                borderRadius: 4,
                padding: '4px 16px',
            }
        },
        mf_sticky_info_box: {
            background: '#EAF2FD',
            padding: '8px 16px',
            marginTop: 0,
            position: 'absolute',
            boxSizing: 'border-box',
            top: -35,
            width: '100%',
            textAlign: 'center',
            left: 0,
        },
        mf_sticky_info_text: {
            textAlign: 'center',
            color: '#363F72',
            fontSize: 14,
            fontWeight: 500,
            boxSizing: 'border-box',
            lineHeight: '20px',
            '@media (max-width: 390px)': {
                fontSize: 12,
            }
        },
        mf_info_text: {
            textAlign: 'center',
            color: '#363F72',
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '22px'
        },
        mf_info_logoName: {
            color: '#E7424B',
            fontWeight: 600,
        }
    }))