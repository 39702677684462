import React, { useContext, useEffect, useMemo, useState, } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import useTheme from "@material-ui/core/styles/useTheme";
import { useParams } from "react-router-dom";
import loadable from '@loadable/component';
import Context from "../../../context/Context";
import SearchIcon from "@material-ui/icons/Search";
import { GIVE_LOGO } from "../../../config";
// import { COLORS } from "../../../assets/variables";
import { useFundraisersNavbarStyles } from "./fundraiserNavbar.style";
import LazyLoadImages from "../../../components/common/Image/LazyLoadImages";
import { constantImages as IMG_CONST } from "../../../imageConstant";
import { pageRoutes as ROUTE } from "../../../routeConstant";
import ServerContext from "../../../context/ServerContext";
import Analytics from "../../../utils/analytics";
import PartnerHeader from "./components/PartnerHeader";
import { getSearchParams } from "../../../utils/helpers";


const CurrencySelect = loadable(() => import("../Navbar/CurrencySelect/CurrencySelect"));
const Login = loadable(() => import("../../Dialogs/Login"));

interface IProps{
  isPartnerPage?:boolean,
  campaignDetails?:any,
  slug?: any
}

const Navbar = ({isPartnerPage}:IProps) => {

  const theme = useTheme();
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const slug: any = useParams();

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useFundraisersNavbarStyles();
  const context = useContext(Context);
  const userContext = context?.User;
  const logout = userContext?.logout
  const user = userContext?.state?.userData;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const serverContext: any = useContext(ServerContext);
 const campaignContext = context?.Campaign;
  let campaignData = serverContext?.campaignData || campaignContext?.state?.campaignData;
  let supportCampaignHeight= document.getElementById("support-campaign")?.clientHeight;
 const isProfileIcon = useMemo(() => {
    if(user && Object.keys(user).length){
      const {is_email_verified,is_phone_verified} = user;
      return is_email_verified || is_phone_verified
    } else return false
  }, [user])

  const handleRedirect = (link: any) => {
    if (window) {
      window.location.href = link
    }
  }
  const handleLoginButtonClick = () => {
    setShowLoginDialog(true);
  };
  const handleCloseLoginDialog = () => {
    setShowLoginDialog(false);
  };

  const handleLogout = async () => {
    if (typeof window === "object") {
      setAnchorEl(null);
      var localStorageArray: any = [];
      for (var localStorageIndex = 0; localStorageIndex < localStorage.length; localStorageIndex++) {
        if (localStorage.key(localStorageIndex)?.includes("fundraiserData-") || localStorage.key(localStorageIndex)?.includes("token") || localStorage.key(localStorageIndex)?.includes("userData")) {
          localStorageArray.push(localStorage.key(localStorageIndex));
        }
      }

      if (logout) {
        try {
          logout().then((response: any) => {
            // Remove such items from Array
            for (var deleteIndex = 0; deleteIndex < localStorageArray.length; deleteIndex++) {
              localStorage.removeItem(localStorageArray[deleteIndex]);
            }
            window?.location.reload();
          })
        }
        catch (error) {

          for (var deleteIndex = 0; deleteIndex < localStorageArray.length; deleteIndex++) {
            localStorage.removeItem(localStorageArray[deleteIndex]);
          }
        }
      }
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setShowDropDown(true)
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    setShowDropDown(false)
  }

  //To get the scroll event to hide the sticky give button
  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  // scroll event callback function
  const onScroll = (e: Event) => {
    setShowDropDown(false)
    setAnchorEl(null);
  }

  useEffect(() => {
    const gtag_info = {
      user: {
        login_method: "" ? "" : null,
        login_status: user?._id ? "logged-in" : "logged-out",
        id: user?._id ? user?._id : null,
      },
    };
    global.window && global.window.appendDataLayer && global.window.appendDataLayer("user", gtag_info.user);
  }, [slug, user]);

  return (
    <>
      {showLoginDialog && <Login openDialog={showLoginDialog} handleClose={handleCloseLoginDialog} reloadAfterLogin={true} />}
      <AppBar className={classes.appBar}>
        <Toolbar id={campaignData?.parent_data && "sticky-header"} className={`${classes.headerBar} ${!campaignData?.parent_data && classes.fixedHeader}`} 
          style={{top:isSmall? '0':'', left: isSmall ? '0':''}}>
          {/* <Toolbar className={`${campaignData?.is_closed ? classes.headerBarWithClosedBanner : classes.headerBar}`}> */}
          <Grid md={2} item className={classes.logo} onClick={() => {
              Analytics.amplitudeEvents('landingpageTappedGiveLogo', {user: user});
              return handleRedirect(`/${getSearchParams()}`);
            }
          }>
            <LazyLoadImages src={GIVE_LOGO} alt="Give Logo" />
          </Grid>
          { isPartnerPage? <PartnerHeader/>:
          <Grid md={10} item>
            <Box display={'flex'} justifyContent="flex-end" alignItems={"center"}>
              {
                isSmall ? null :
                  <>
                    <a href="/find-more/" onClick={()=>{
                      Analytics.amplitudeEvents('search_to_FindMore', { user: user })
                    }} className={classes.findMore}>
                      <SearchIcon className={classes.searchIcon} /> <span className={classes.fundraiser}>Find fundraisers</span>
                    </a>
                    <Grid className={classes.wrap}><CurrencySelect /></Grid>
                  </>
              }
              {isProfileIcon ? (
                <li className="leftLinks">
                  <div className="d-flex align-items-center cursor-pointer" onClick={handleClick}>
                    <div className="user-profile-wrap" >
                        <img src={user?.profile_pic}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = IMG_CONST.DEFAULT_IMAGE;
                          }}
                          alt={user?.name} />
                    </div>
                  </div>
                </li>
              ) : (
                isSmall ?
                  <Box>
                    <Button size="large" href="" className={classes.signUpBtn} onClick={handleLoginButtonClick}>Sign Up</Button>
                  </Box> :
                  <Box>
                    <Grid container>
                      <Grid>
                        <Button variant="contained" color="primary" size="large"
                          className={classes.loginButton} onClick={handleLoginButtonClick}>Login
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
              )}
              <Menu
                id={id}
                anchorEl={anchorEl}
                keepMounted
                disableScrollLock={true}
                open={open || showDropDown}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handleCloseMenu}
                className={classes.loginMenu}
              >
                <MenuItem className={classes.menuItem}>
                  <Link href={ROUTE.PROFILE} className={classes.profileButton}>Profile</Link>
                </MenuItem>
                <MenuItem className={classes.menuItem}
                  onClick={handleLogout}>Log Out</MenuItem>
              </Menu>
            </Box>
          </Grid>}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
