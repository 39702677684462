import * as interfaces from "../interfaces";

export const initialState: interfaces.editCampaign = {
  editCampaignData: {},
  editGalleryData: [],
  removedMemberIds: [],
  removedMemberData:[],
  editUpdatesData: {},
  updatesListData: [],
  updatesDeleteData: [],
};

export const reducer = (state: any, action: interfaces.action) => {
  switch (action?.type) {
    case "edit-campaign-data":
      return { ...state, editCampaignData: action.value };
    case "edit-gallery-data":
      return { ...state, editGalleryData: action.value };
    case "edit-campaign-profile-data":
      return { ...state, editCampaignData: { ...state.editCampaignData, creator: action.value} };
    case "remove-member-data":
      return {...state, removedMemberData: action.value}
    case "remove-member-id-data":
      return {...state, removedMemberIds: action.value}
    case "faqs-list-data":
      return { ...state, editCampaignData: {...state.editCampaign, faqs : action.value} };
    case "edit-updates-data":
      return { ...state, editUpdatesData: action.value };
    case "updates-list-data":
      return { ...state, updatesListData: action.value };
    case "updates-delete-data":
      return { ...state, updatesDeleteData: action.value };
    case "reset":
      return initialState;
  }
};
