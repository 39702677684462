import { differenceInMinutes, differenceInHours, differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';

export const convertCurrency = (
  amount: any,
  currency?: string,
  nosymbol?: Boolean
) => {
  if (!currency) {
    currency = "INR";
  }

  const locale = currency === "USD" ? "en-US" : "en-IN";

  if (amount === undefined) {
    return Intl.NumberFormat(locale, {
      style: "decimal",
      currency: currency,
      minimumFractionDigits: 0,
    })
      .format(0)
      .replace(/^(\D+)/, "$1 ");
  } else if (nosymbol) {
    return Intl.NumberFormat(locale, {
      style: "decimal",
      currency: currency,
      minimumFractionDigits: 0,
    })
      .format(amount)
      .replace(/^(\D+)/, "");
  } else {
    return Intl.NumberFormat(locale, {
      style: "decimal",
      currency: currency,
      minimumFractionDigits: 0,
    })
      .format(amount)
      .replace(/^(\D+)/, "$1 "); // adding a space after currency symbol
  }
};

// Progress Bar for campaign funds
export const getAmountRatio = (totalAmount: any, currentAmount: any) => {
  let percentage = (currentAmount / totalAmount) * 100;
  if (percentage < 100) {
    return (currentAmount / totalAmount) * 100;
  } else if (percentage >= 100) {
    return 100;
  } else {
    return 0;
  }
};

export const getDateWithoutTimeRequiredFormat = (ISODate: string) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const requiredDate = new Date(ISODate);
  const date = requiredDate.getDate();
  const month = monthNames[requiredDate.getMonth()];
  const year = requiredDate.getFullYear();

  return `${date} ${month}, ${year}`;
};

export const getDateWithoutTimeRequiredFormatForInput = (ISODate: string) => {
 
  const requiredDate = new Date(ISODate);
  let date = requiredDate.getDate().toLocaleString();
  
  if(+date < 10){
    date = date.toString().padStart(2,"0")
  }
  let month = (requiredDate.getMonth()+1).toLocaleString();
  if(+month < 10){
    month = month.toString().padStart(2,"0")
  }
  const year = requiredDate.getFullYear();

  return `${year}-${month}-${date}`;
};
export const getDateShortMonthWOTimeRequiredFormat = (ISODate: string) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const requiredDate = new Date(ISODate);
  const date = requiredDate.getDate();
  const month = monthNames[requiredDate.getMonth()];
  const year = requiredDate.getFullYear();

  return `${date} ${month}, ${year}`;
};

export const getDateWithTime = (ISODate: string) => {
  const requiredDate = new Date(ISODate);
  const time = requiredDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  })
  return `${time}`;
}

export const checkForImageInGallery = (gallery: any) => {
  let galleryIndex: number = 0;
  let noImageFound = -1;
  for (galleryIndex = 0; galleryIndex < gallery.length; galleryIndex++) {
    if (gallery[galleryIndex].media_type === "gallery_image") {
      return gallery[galleryIndex].uri;
    } else {
      noImageFound++;
    }
  }

  if (noImageFound !== -1) {
    return "";
  }
};

export const getDateWithTimeRequiredFormat = (requiredDate: string) => {
  let date = new Date(requiredDate);
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let fmt_time = hours + ":" + minutes + " " + ampm;
  return `${date.toDateString()}, ${fmt_time} `;
};


export const getTimeElapsed = (dateStr:string):string => {
    const now = new Date();
    const date = new Date(dateStr);
    const minutesDifference = differenceInMinutes(now, date);

    if (minutesDifference < 60) {
        return `${minutesDifference} min ago`;
    }

    const hoursDifference = differenceInHours(now, date);
    if (hoursDifference < 24) {
        if(hoursDifference === 1){
          return `${hoursDifference} hour ago`;
        }
        return `${hoursDifference} hrs ago`;
    }

    const daysDifference = differenceInDays(now, date);
    if (daysDifference < 30) {
        if(daysDifference === 1) {
          return `${daysDifference} day ago`;
        }
        return `${daysDifference} days ago`;
    }

    const monthsDifference = differenceInMonths(now, date);
    if (monthsDifference < 12) {
        if(monthsDifference === 1){
          return `${monthsDifference} month ago`;
        }
        return `${monthsDifference} months ago`;
    }

    const yearsDifference = differenceInYears(now, date);
    if(yearsDifference === 1){
      return `${yearsDifference} year ago`;
    }
    return `${yearsDifference} years ago`;
}

