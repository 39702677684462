import React, { useState, useContext } from "react";
import ServerContext from "./context/ServerContext";
const useServerEffect = (initial: any, key: any, effect: any) => {
  const context: any = useContext(ServerContext);
  const [data] = useState(context[key] || initial);

  if (context.requests) {
    context.requests.push(
      effect().then((data: any) => {
        context[key] = data.data;        
      })
    );
  }
  
  return [data];
};
export default useServerEffect;
