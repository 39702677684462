export const constantImages = {
  UPI_METHODS_IMAGE: "https://cfstatic.give.do/ca647d23-2a41-41e9-8114-739c80a55541.webp",
  ATM_CARD_IMAGE: "https://cfstatic.give.do/f49d2a60-6a84-4143-bf6b-fbc4901e024d.webp",
  BANK_TRANSFER_IMAGE: "https://cfstatic.give.do/2b7b0d8a-c9cc-49ad-be7c-816c76a4dd4c.webp",
  DEFAULT_CAMPAIGN_IMAGE: "https://cfstatic.give.do/17b34b36-1c20-42ad-a9fe-abe6b98529ca.webp",
  SIMPLE_IMAGE: "https://cfstatic.give.do/ca12c242-cadf-4e0a-b951-b42b6ef581c6.webp",
  POWERFUL_IMAGE: "https://cfstatic.give.do/b165cc73-e2d6-4d69-8d8a-ff75e36f41b7.webp",
  TRUSED_IMAGE: "https://cfstatic.give.do/35214b72-3a0c-4281-89e8-7bfb6637543e.webp",
  DEFAULT_ORGANIZER_IMAGE: "https://cfstatic.give.do/bca44fc5-af4c-46cb-b2f9-700d6ed6f5df.webp",
  DEFAULT_NGO_IMAGE: "https://cfstatic.give.do/fe165514-dd18-4bf7-99fb-e50725233f7a.webp",
  DEFAULT_DONOR: "https://cfstatic.give.do/a5e7242a-5b11-4947-9dd0-4fc18931fe5c.webp",
  EDIT_ICON: "https://cfstatic.give.do/1f3a892f-11de-4b1b-8776-999d3e116e75.webp",
  DEFAULT_IMAGE: "https://cfstatic.give.do/a5e7242a-5b11-4947-9dd0-4fc18931fe5c.webp",
  FB_ICON: "https://cfstatic.give.do/a4f5116b-6ab9-43c9-8c89-94bf713ed192.webp",
  TWT_ICON: "https://cfstatic.give.do/09038dd9-b600-4ad9-831b-eaaddcd9adcf.webp",
  INSTA_ICON: "https://cfstatic.give.do/17f31820-e415-413c-9e62-762d0adb955e.webp",
  YOUTUBE_ICON: "https://cfstatic.give.do/474b27fb-3279-47e4-aa89-1e6d6e4e9a2b.webp",
  LINKDIN_ICON: "https://cfstatic.give.do/cf65e2ce-e11b-43df-852e-5a95432d7e25.webp",
  PLACEHOLDER: "https://cfstatic.give.do/394b03bf-e904-4f00-8e85-d1cca4316f02.png",
  YOUTUBE_IMAGE: "https://cfstatic.give.do/ebfcfd2b-a111-4489-99e0-b706bcbee15b.png",
  WHATSAPP_ICON: "https://cfstatic.give.do/5b5f32fd-a40b-48db-ac1b-3b4d363456ed.png",
  TWITTER_ICON: "https://cfstatic.give.do/87c6ad58-2d72-4c68-a987-be902f17a3e1.png",
  LINKED_IN_ICON: "https://cfstatic.give.do/96cc2723-e6b2-4d61-8d51-c11ab169c78d.png",
  FACEBOOK_ICON: "https://cfstatic.give.do/4f97769c-80b5-4474-ba5f-211b07beacee.png",
  EMAIL_ICON: "https://cfstatic.give.do/cd33f282-46ca-4e08-9227-870ac7473a5d.png",
  COPY_ICON: "https://cfstatic.give.do/49dc363b-d024-4f77-82c2-4527bdccdb9f.png",
  RAISE_FUNDS_IMAGE: "https://cfstatic.give.do/15a94e94-f3aa-48c2-9bac-487e22a14b37.webp",
  GET_INVOLVED_IMAGE: "https://cfstatic.give.do/85e743a3-46c7-4480-b97c-abd7d5354abf.webp",
  WHATSAPP_CHAT_IMAGE: "https://cfstatic.give.do/e88ac24b-3f1d-470e-8337-40d363e0c47e.webp",
  CAMPAIGN_CARD_DONORS_IMAGE: "https://cfstatic.give.do/cfb41077-269b-4376-a68a-aa45355843fd.webp",
  DEFAULT_IMAGE_SUPPORT_FUNDRAISERS: "https://cfstatic.give.do/17b34b36-1c20-42ad-a9fe-abe6b98529ca.webp",
  PDF_ICON: "https://cfstatic.give.do/1ca06140-b320-4788-97ab-b02605e38733.webp",
  MONTHLYDONATION_NCO_IMAGE: "https://cfstatic.give.do/bcd699f7-3210-4cb4-b840-549dd96de5b8.webp",
  SEARCH_ICON: "https://cfstatic.give.do/e609f55d-37ca-4097-85cc-c1a79460cd5b.webp",
  FILTER_ICON: "https://cfstatic.give.do/128922a6-7dc7-4f19-845a-a638f6e82eb7.webp",
  CLOSE_ICON:"https://cfstatic.give.do/352144cb-7df4-4d80-9e1d-fff0eafb96f6.webp",
  UP_ARROW_ICON:"https://cfstatic.give.do/a9010b6f-f1a6-4f15-9fc6-5624a419f8e5.webp",
  NOT_FOUND_ICON:"https://cfstatic.give.do/873d7270-17ec-411f-ad62-3a378250a769.webp",
  SHOW_LESS_ICON:"https://cfstatic.give.do/006fbfde-2414-4f8d-a73a-f2913e7bfbc0.webp"
};
