// import MoEngage from './moEngage'
import { MOENGAGE_APP_ID } from "../config";
import DBStorage from './DBStorage'
import { logAmplitudeEvent, setUserIdData } from './amplitude';
import { fetchUtmParamsInCookies, getTags } from './helpers';

const hash = require('hash.js')
// const MOE = new MoEngage(MOENGAGE_APP_ID)

const EVENT_TYPE: any = {
  productDetail: 'CF_LANDINGPAGE',
  addToCart: 'CF_LANDINGPAGE_CTA',
  'select-currency': 'CF_LANDINGPAGE_SELECTCURRENCY',
  'select-amount': 'CF_LANDINGPAGE_SELECTAMOUNT',
  'supporting-fundraisers': 'CF_START_SUPPORTINGFUNDRAISER',
  'fundraiser-share': 'CF_LANDINGPAGE_SOCIALSHARE',
  'user-login': 'CF_LOGGED_IN'
}

declare global {
  interface Window {
    appendDataLayer: any;
  }

  interface address {
    area_code: string,
    region: string,
    locality: string,
    country_code: string,
    city: string
  }
  interface stastics {
    raised_amount: number
  }

  interface donationStats {
    last_successful_donation_timestamp: string,
    last_un_successful_donation_timestamp: string,
    last_un_sucessfull_donation_status: string,
    total_successful_transactions: number,
    total_donations: number,
    last_sucessfull_donation_status: string
  }
  interface data {
    email: string,
    phone: string,
    name: string,
    address: address,
    statistic: stastics,
    _id: string,
    user_ip: string,
    donation_stats: donationStats,
    whatsappConsent: boolean
  }
  interface Reminder {
    reminder_name: string;
    reminder_date: string;
    reminder_occasion: string;
    reminder_id: number;
    reminder_active: boolean;
  }
}

class Analytics {

  static checkParams(data: any) {
    if (
      data.hasOwnProperty('id') &&
      data.hasOwnProperty('name') &&
      data.hasOwnProperty('brand') &&
      data.hasOwnProperty('variant') &&
      data.hasOwnProperty('category')
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  static transformCustomerData = (data: data) => {
    return {
      em: data.email ? hash.sha256().update(data.email).digest('hex') : undefined,
      ph: data.phone ? hash.sha256().update(data.phone).digest('hex') : undefined,
      fn: data.name ? hash.sha256().update(data.name).digest('hex') : undefined,
      zp: data.address?.area_code ? hash.sha256().update(data.address.area_code).digest('hex') : undefined,
      st: data.address?.region ? hash.sha256().update(data.address?.region).digest('hex') : undefined,
      ct: data.address?.city ? hash.sha256().update(data.address?.city).digest('hex') : undefined,
      country: data.address?.country_code ? hash.sha256().update(data.address?.country_code.toLocaleLowerCase()).digest('hex') : undefined
    }
  }

  static transformCustomerUnHashedData = (data: data) => {
    return {
      em: data.email ? data.email : undefined,
      ph: data.phone ? data.phone : undefined,
      fn: data.name ? data.name : undefined,
      zp: data.address?.area_code ? data.address.area_code : undefined,
      st: data.address?.region ? data.address?.region : undefined,
      ct: data.address?.city ? data.address?.city : undefined,
      country: data.address?.country_code ? data.address?.country_code : undefined,
      raised_amount: data.statistic?.raised_amount ? data.statistic.raised_amount : undefined,
      user_id: data._id ? data._id : undefined,
      user_ip: data.user_ip,

      last_successful_donation_timestamp: data?.donation_stats?.last_successful_donation_timestamp ? data?.donation_stats.last_successful_donation_timestamp : undefined,
      last_un_successful_donation_timestamp: data?.donation_stats?.last_un_successful_donation_timestamp ? data?.donation_stats.last_un_successful_donation_timestamp : undefined,
      last_un_sucessfull_donation_status: data?.donation_stats?.last_un_sucessfull_donation_status ? data?.donation_stats.last_un_sucessfull_donation_status : undefined,
      last_sucessfull_donation_status: data?.donation_stats?.last_sucessfull_donation_status ? data?.donation_stats.last_sucessfull_donation_status : undefined,
      total_successful_transactions: data?.donation_stats?.total_successful_transactions ? data?.donation_stats.total_successful_transactions : undefined,
      total_donations: data?.donation_stats?.total_donations ? data?.donation_stats.total_donations : undefined,
      whatsappConsent: data?.whatsappConsent
    }
  }


  static productDetail(data: any, user: any = {}, utm: any, user_ip: any) {

    const thisUser = this.transformCustomerUnHashedData({ ...user, user_ip })
    if (this.checkParams(data)) {
      let analyticsData = {
        identifier: data.id,
        transaction: {
          event: 'productDetail',
          id: data.id,
          price: data.amount ? data.amount : 1,
          name: data.name,
          title: data.title,
          brand: data.brand,
          variant: data.variant,
          category: data.category,
          is_donation_inorganic: utm && String(utm?.utm_term).toLowerCase() === 'campaign' ? true : false,
          is_referred_by_user: utm && String(utm?.utm_campaign).toLowerCase() === 'campaignshare' ? true : false,
          is_give_nonprofit_flag: data.nonprofit_slug === 'give-foundation' ? true : false,
          donation_currency: data.donation_currency,
        },
        customer: this.transformCustomerData(user),
        donor_info: thisUser
      };
      this.updateDataLayer(analyticsData);
    }
  }

  static addToCart(data: any, user: any = {}) {
    const thisUser = this.transformCustomerUnHashedData(user)
    if (this.checkParams(data)) {
      let analyticsData = {
        identifier: data.id,
        transaction: {
          event: 'addToCart',
          id: data.id,
          price: data.amount ? data.amount : null,
          currency_symbol: data.currency_symbol ? data.currency_symbol : null,
          name: data.name,
          title: data.title,
          brand: data.brand,
          variant: data.variant,
          category: data.category,
          donation_currency: data.donation_currency,
          revenue_type: data.revenue_type
        },
        customer: this.transformCustomerData(user),
        donor_info: thisUser
      };
      this.updateDataLayer(analyticsData);
    }
  }

  static currencyChange(data: any) {
    let analyticsData = {
      fundraiser_name: data.slug,
      fundraiser_id: data.id,
      currency_code: data.currencyCode
    };
    this.updateDataLayer(analyticsData, 'select-currency');
  }

  static selectAmount(data: any) {
    let analyticsData = {
      fundraiser_name: data.slug,
      fundraiser_id: data.id,
      is_default_amount: data.isDefaultAmount,
      amount: data.price
    };
    this.updateDataLayer(analyticsData, 'select-amount');
  }

  static login(data: any) {
    let analyticsData = {
      donorInfo: {
        em: data.email ? data.email : undefined,
        ph: data.phone ? data.phone : undefined,
        fn: data.name ? data.name : undefined,
        zp: data.address?.area_code ? data.address.area_code : undefined,
        st: data.address?.region ? data.address?.region : undefined,
        ct: data.address?.city ? data.address?.city : undefined,
        country: data.address?.country_code ? data.address?.country_code : undefined,
        raised_amount: data.statistic?.raised_amount ? data.statistic.raised_amount : undefined,
        user_id: data._id ? data._id : undefined,
        user_ip: data.user_ip
      },
      user_id: data._id ? data._id : undefined
    }
    setUserIdData(data?.email)
    this.updateDataLayer(analyticsData, 'user-login');
  }

  static logout(userData: any) {
    // MOE.triggerLogout(userData)
    // MOE.destroySession()
  }

  static handleGiveNow(data: any) {
    let analyticsData = {
      fundraiser_name: data.slug,
      fundraiser_id: data.id,
      is_default_amount: data.isDefaultAmount,
      amount: data.price,
      redirection_checkout_status: data.redirection_checkout_status
    };
    this.updateDataLayer(analyticsData, 'redirect-checkout');
  }

  static startASupportingFundraiser(data: any) {
    let analyticsData = {
      fundraiser_name: data.slug,
      fundraiser_id: data.id
    };
    this.updateDataLayer(analyticsData, 'supporting-fundraisers');
  }

  //Amplitude Events for the create fundraiser 
  static async amplitudeEvents(eventFor: string, data: any) {
    if (data) {
      let isGuestUser;
      if (data?.user&&Object.keys(data?.user).length) {
        isGuestUser = data?.user?.is_guest
      }
      else isGuestUser = true
      let ampData: any = {
        user_type: isGuestUser ? 'Guest' : 'Logged in User',
        user_id: data?.user && data?.user?._id || '',
        is_guest: isGuestUser,
      }
      if (
        eventFor === "createFundraiser_login_cta" ||
        eventFor === "createFundraiserLoadLoginPopup" ||
        eventFor === 'landing_on_homePage' ||
        eventFor === 'start_Fundraiser_Clicked_Menu' ||
        eventFor === 'start_Raising_Funds_Clicked' ||
        eventFor === 'homepage_Enroll_NGO_Clicked' ||
        eventFor === 'landing_on_choose_purpose_Page' ||
        eventFor === "ngo_startedClicked" ||
        eventFor === "medical_getStartedClicked" ||
        eventFor === "landing_on_featured_campaign" ||
        eventFor === "showMore_clicked" ||
        eventFor === "viewDetails_clicked" ||
        eventFor === "landing_fundraiser_homePage" ||
        eventFor === "browse_fundraiser_clicked" ||
        eventFor === "most_funded_NGO" ||
        eventFor === "most_recent_NGO" ||
        eventFor === "most_popular_NGO" ||
        eventFor === "most_funded_Campaign" ||
        eventFor === "most_recent_Campaign" ||
        eventFor === "most_popular_Campaign" ||
        eventFor === "medical_fundraiser_cta" ||
        eventFor === "medical_fr_verify_complete_cta" ||
        eventFor === 'search_to_FindMore' || //click on create  fundraiser page leads to /find-more link
        eventFor === "searchFundraisers" ||
        eventFor === "homepageTappedGiveLogo" ||
        eventFor === "landingpageTappedGiveLogo" ||
        eventFor === "monthlyMissionClicked"||
        eventFor === "profilePage"||
        eventFor === "profileMenuTabClicked"||
        eventFor === "givestarClickedQuickAction"
      ) { //search fundraiser icon button on homepage
        await logAmplitudeEvent(eventFor, ampData)
      }
      else if (eventFor === "secondary_banner_click") {
        const { text, link } = data || {}
        const clickData = { text, link }
        await logAmplitudeEvent(eventFor, clickData)

      }
      else if (eventFor === "homePageNotification") {
        const { text, link } = data || {}
        const clickData = { text, link }       
        await logAmplitudeEvent(eventFor, clickData)
      }
      else if (eventFor === "search_on_fundraiserListingPage" || // homepage modal search
        eventFor === "search_on_createPage" //create fundraiser search page 
      ) {
        ampData.search_string = data?.search_string
        await logAmplitudeEvent(eventFor, ampData)
      }
      else if (eventFor === "choose_NonProfit") {
        ampData.nonprofit_id = data?.nonprofit_id || ''
        await logAmplitudeEvent('choose_NonProfit', ampData)
      }
      else if (eventFor === "create_fundraiser_supporting" ||
        eventFor === "landing_on_create_fundraiser_form" ||
        eventFor === "choose_Campaign" ||
        eventFor === "create_fundraiser_cta") {
        ampData.campaign_id = data?.campaign_id || ''
        ampData.nonprofit_id = data?.nonprofit_id || ''
        await logAmplitudeEvent(eventFor, ampData)
      }
      else if (eventFor === "change_cause_from_dropdown") {
        ampData.campaign_id = data?.campaign_id || ''
        ampData.nonprofit_id = data?.nonprofit_id || ''
        ampData.cause_type = data?.cause_type
        await logAmplitudeEvent('change_cause_from_dropdown', ampData)
      }
      else if (eventFor === "cause_choose") {
        ampData.cause_type = data?.cause_type
        await logAmplitudeEvent('cause_choose', ampData)
      }
      //Fundraiser listing page events
      else if (eventFor === "frListingPageView" || eventFor === "frListingExploreCause" ||
        eventFor === "frListingBlogClicked" || eventFor === "frListingFilterOptions" ||
        eventFor === "frListing_FundraiserClicked" || eventFor === "frListing_FundraiserCTAClicked" ||
        eventFor === "frmonthlyFundraiseClicked" || eventFor === "frListingSearch" || eventFor==='spotlightMonthlyClicked'||
        eventFor === "frListingFilterCause" || eventFor === "frListingFilterNGOSearch"
      ) {
        if (data?.fundraiserData) {
          const tags = getTags(data?.fundraiserData?.tags);
          const donation_tags = tags.length > 0 ? tags?.join(', ') : '';
          ampData.fundraiser_tags = donation_tags || ''
          ampData.fundraiser_id = data?.fundraiserData && data?.fundraiserData?.fundraiser_id || ''
          ampData.fundraiser_title = data?.fundraiserData && data?.fundraiserData?.fundraiser_title || ''
          ampData.fundraiser_type = data?.fundraiserData && data?.fundraiserData?.fundraiser_type || ''
          ampData.non_profit = data?.fundraiserData && data?.fundraiserData?.nonprofit || ''
          ampData.non_profit_id = data?.fundraiserData && data?.fundraiserData?.nonprofit_id || ''
        }
        if (data?.filterData) {
          ampData.selected_ngos = data?.filterData?.selected_ngos || ''
          ampData.urgent_funds_toggle = data?.filterData?.urgent_funds_toggle || false
          ampData.tax_benefits_toggle = data?.filterData?.tax_benefits_toggle || false
        }
        if (data?.search_string) { ampData.search_string = data?.search_string || '' }
        if (data?.cause) { ampData.cause = data?.cause || '' }
        if (data?.slot_no) { ampData.slot_no = data?.slot_no }
        if (data?.slug) { ampData.slug = data?.slug }
        if (data?.timestamp && data?.blog_link) { //blog clicked on fr listing page
          ampData.timestamp = data?.timestamp || ''
          ampData.blog_no = data?.blog_no || ''
          ampData.blog_link = data?.blog_link || ''
        }
        ampData.utm_content = data?.utmParameters && data?.utmParameters?.utm_content || ''
        ampData.utm_source = data?.utmParameters && data?.utmParameters?.utm_source
        ampData.utm_referal_source = fetchUtmParamsInCookies() ? fetchUtmParamsInCookies() : 'direct'
        ampData.utm_medium = data?.utmParameters && data?.utmParameters?.utm_medium || ''
        ampData.utm_term = data?.utmParameters && data?.utmParameters?.utm_term || ''
        ampData.utm_campaign = data?.utmParameters && data?.utmParameters?.utm_campaign || ''
        ampData.utm_type = data?.utmParameters && data?.utmParameters?.utm_type || ''
        ampData.utm_id = data?.utmParameters && data?.utmParameters?.utm_id || ''
        await logAmplitudeEvent(eventFor, ampData)
      } else if( eventFor === 'ngoCreateFundraiser' || 
        eventFor === 'ngoPlayVideo' || 
        eventFor === 'ngoImportantLink' || 
        eventFor === 'ngoSimilarFRDonateCTA' ||
        eventFor === 'ngoFRListFundraiserClicked' ||
        eventFor === 'ngoSimilarFRClicked' || 
        eventFor === 'ngoFRListDonatCTA' || 
        eventFor === 'ngoCreateSupportingFundraiser' ||
        eventFor === 'locationBasedPageviewed' ||
        eventFor === 'locationBasedClicked' ||
        eventFor === 'combinedMonthlyLpPageview' ||
        eventFor === 'clickedMonthlyFundraiserCombinedLp' 
        ) {
        ampData.nonProfitData = data?.nonProfitData || ''
        ampData.utm_content = data?.utmParameters && data?.utmParameters?.utm_content || ''
        ampData.utm_source = data?.utmParameters && data?.utmParameters?.utm_source
        ampData.utm_referal_source = fetchUtmParamsInCookies() ? fetchUtmParamsInCookies() : 'direct'
        ampData.utm_medium = data?.utmParameters && data?.utmParameters?.utm_medium || ''
        ampData.utm_term = data?.utmParameters && data?.utmParameters?.utm_term || ''
        ampData.utm_type = data?.utmParameters && data?.utmParameters?.utm_type || ''
        ampData.utm_id = data?.utmParameters && data?.utmParameters?.utm_id || ''
        ampData.utm_position = data?.utmParameters && data?.utmParameters?.utm_position || ''
        ampData.utm_page = data?.utmParameters && data?.utmParameters?.utm_page || ''
        ampData.user = data?.userData || ''
        ampData.cta_type = data?.cta_type || ''
        ampData.cta_location = data?.cta_location || ''
        ampData.title = data?.title || ''
        ampData.url = data?.url || ''
        ampData.fundraiser_title = data?.fundraiser_title || ''
        ampData.fundraiser_id = data?.fundraiser_id || ''
        ampData.slot_no = data?.slot_no || ''
        if(data?.fundraiserData) {
          const tags = getTags(data?.fundraiserData?.tags);
          const donation_tags = tags.length > 0 ? tags?.join(', ') : '';
          ampData.fundraiser_tags = donation_tags || ''
          ampData.fundraiser_id = data?.fundraiserData && data?.fundraiserData?.fundraiser_id || ''
          ampData.fundraiser_title = data?.fundraiserData && data?.fundraiserData?.fundraiser_title || ''
          ampData.fundraiser_type = data?.fundraiserData && data?.fundraiserData?.fundraiser_type || ''
          ampData.fundraiser_slot_no = data?.fundraiserData && data?.fundraiserData?.fundraiser_slot_no || ''
        }
        if(eventFor === 'ngoImportantLink') {
          ampData.linkName = data?.linkName || ''
          ampData.url = data?.url || ''
        }
        if(eventFor === 'ngoCreateSupportingFundraiser') {
          ampData.utm_position = data?.utmParameters && data?.utmParameters?.utm_position || ''
        }
        await logAmplitudeEvent(eventFor, ampData);
      }
      else{
        const eData = {...ampData,...data}
        await logAmplitudeEvent(eventFor, eData)
      }
    }
  }


  static ReminderEvent(eventType: string, eventData?: any) {
    // if (eventType === "SAVE") {
    //   MOE.track('SAVE_REMINDER', eventData)
    // }
    // else if (eventType === "DELETE") {
    //   MOE.track('DELETE_REMINDER', eventData)
    // }
    // else if (eventType === "UPDATE") {
    //   MOE.track('UPDATE_REMINDER', eventData)
    // }
    this.updateDataLayer(eventData, 'add-reminder')
  }

  static shareFundraiser(data: any) {
    let analyticsData = {
      fundraiser_name: data.fundraiser_name,
      fundraiser_id: data.fundraiser_id,
      share_medium: data.share_medium
    };
    this.updateDataLayer(analyticsData, 'fundraiser-share');
  }

  //create fundraiser
  static createFundraiser(data: any) {
    let analyticsData = {
      cause: data.causes[0]?.name,
      fundraiser_expiry_date: data.expiry_date,
      is_primary: data.is_primary,
      location: data.location,
      nonprofit_id: data.nonprofit_id,
      target_amount: data.target_amount,
      title: data.title,
      type: data.type,
      user_id: data.user_id,
      fundraiser_id: data.fundraiser_id,
      page_path: data.page_path
    };
    this.updateDataLayer(analyticsData, 'create-fundraiser');
  }

  static updateDataLayer(data: any, type?: any) {
    try {
      global.window && global.window.appendDataLayer && global.window.appendDataLayer('event',
        {
          type: type || 'donate',
          data: data
        })
    }
    catch (e) {
      console.error('AppendDataLayer Call failed with error:', e);
    }

    try {
      let eventName: any;
      // if (data && data.donor_info) MOE.userInit(data.donor_info)
      if (type === 'user-login') {
        eventName = EVENT_TYPE[type]
        // MOE.track(eventName, {
        //   user_id: data.user_id
        // })
        DBStorage.set('USER_LOGIN_CAPTURED', true)
      }
      else {
        if (data?.transaction?.event && Object.keys(EVENT_TYPE).indexOf(data.transaction.event) !== -1) eventName = EVENT_TYPE[data.transaction.event]
        else if (type && Object.keys(EVENT_TYPE).indexOf(type) !== -1) eventName = EVENT_TYPE[type]
        // MOE.track(eventName, data)
      }

    }
    catch (e) {
      console.info(`Failed adding events to MoEngage ${data?.transaction?.event || type || 'Event Name'}`)
      console.debug(e)
    }
  }
}


export default Analytics;