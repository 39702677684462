import { UserIPState } from "context/interfaces";
import * as interfaces from "../interfaces";

export const initialState: { ipData: UserIPState } = {
    ipData : {
        IPv4: null,
        city: null,
        country_code: null,
        country_name: null,
        latitude: null,
        longitude: null,
        postal: null,
        state: null,
    }
}

export const reducer = (state: any, action: interfaces.action) => {
    switch (action?.type) {
      case "user-ip":
        return { ipData: action.value  };
      case "reset":
        return initialState;
    }
};