import { Theme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { COLORS } from '../../../assets/variables';

export const useFundraisersNavbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuPaper: {
            boxShadow: 'none'
        },
        searchIcon: {
            marginRight: 5,
            fontSize: '1.125rem'
        },
        profile_dropdown :{
            listStyleType: 'none',
            padding:'16px',
            margin:0,
            borderRadius: '8px 8px 0px 0px',
            '& li': {
                fontWeight: 500,
                lineHeight: '24px',
                cursor:"pointer"
            },
            '& li:first-child': {
                paddingBottom: "32px"
            },
            '& li:hover':{
                background: 'rgba(253, 241, 226, 0.50)',
            }
        },
        navLink: {
            textDecoration: "none",
            cursor: "pointer",
            padding:"16px",
            boxSizing: 'border-box',
            '@media (max-width:767px)': {
                padding:"14px",
            },
            '&:hover': {
                background: 'rgba(253, 242, 226, 0.5)',
                color: '#222',
                boxShadow: 'none',
            },
          },
          paper: {
            width: "100%",
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            '@media (max-width:767px)': {
                fontSize: '14px',
            }
          },
          arrowIcon: {
            transition: 'transform 0.3s',
          },
          arrowIconRotated: {
            transform: 'rotate(180deg)',
          },
        logo: {
            cursor: "pointer",
            maxWidth: 'max-content',
            '@media (max-width:767px)': {
                height: '44px',
                marginRight: 0,
            },
            "& img": {
                height: '44px',
            },
        },
        profileLogo: {
            cursor: "pointer",
            maxWidth: 'max-content',
            '@media (max-width:767px)': {
                height: '35px',
                maxWidth: '100px',
                marginRight: 0,
                "& img": {
                    height: '35px !important',
                    width: 'auto !important'
                },
            },
            "& img": {
                height: '40px',
                width: '88px'
            },
        },
        headerBar: {
            background: `${COLORS.white} `,
            boxShadow: 'none',
            display: 'flex',
            boxSizing: 'border-box',
            padding: 0,
            justifyContent: 'space-between',
            marginTop: '10px',
            '@media (max-width:767px)': {
                padding: '15px 20px',
                margin: '0',
                width: '100%',
                boxSizing: 'border-box',
            }
        },
        headerBarWithClosedBanner: {
            background: `${COLORS.white} `,
            boxShadow: 'none',
            display: 'flex',
            minHeight: 80,
            boxSizing: 'border-box',
            padding: 0,
            justifyContent: 'space-between',
            '@media (max-width:767px)': {
                padding: '15px 32px',
                margin: '0',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '40px'
            }
        },
        appBar: {
            boxShadow: 'none',
            background: `${COLORS.white} `,
            margin: '0 auto',
            position: "relative",
            width: '100%',      
            '@media (max-width:767px)': {
                // position: "fixed",
            }
        },
    
        loginButton: {
            textTransform: "initial",
            boxShadow: 'none',
            padding: '12px 20px',
            fontSize: '1rem',
            lineHeight: '1.5',
            '&:hover': {
                backgroundColor: COLORS.buttonHoverColor,
                boxShadow: 'none',
            },
            '&:focus': {
                backgroundColor: COLORS.buttonFocusColor,
                boxShadow: 'none',
            }
        },
        signUpBtn: {
            textTransform: "initial",
            fontWeight: 600,
            fontSize: '0.938rem',
            lineHeight: '22px',
            textAlign: 'right',
            color: COLORS.secondaryTextColor,
            boxShadow: 'none',
            padding: 0
        },
        profileButton: {
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
            }
        },
        loginMenu: {
            '& .MuiMenu-list': {
                padding: 0
            },
            "& li": {
                height: 40,
                color: COLORS.tertiaryDark,
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '20px',
                letterSpacing: '0.01em',
            },
            '& li.MuiListItem-button:hover': {
                background: 'rgba(253, 242, 226, 0.5) ',
                color: `${COLORS.tertiaryDark}`,
            },
            '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
                color: COLORS.primary,
                background: 'transparent',
            },
            '& .MuiMenu-paper': {
                marginTop: 50,
                boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.1) !important',
                borderRadius: '8px !important',
                border: `1px solid ${COLORS.titleBorderColor}!important`
            }
        },
        menuItem: {
            padding: '8px 15px ',
            fontSize: '1rem',
            '&:hover': {
                color: COLORS.black
            }
        },
        findMore: {
            color: COLORS.primaryPink,
            marginRight: 16,
            display: 'flex',
            alignItems: 'center'
        },
        fundraiser: {
            color: COLORS.searchTextColor,
            fontWeight: 400,
            textTransform: 'capitalize'
        },
        wrap: {
            marginRight: 16
        },

        fixedHeader:{
            '@media (max-width:767px)': {
                position: "fixed",
            }
        },
        profileHeaderBar: {
            background: `${COLORS.white} `,
            boxShadow: 'none',
            display: 'flex',
            boxSizing: 'border-box',
            padding: 0,
            justifyContent: 'space-between',
            marginTop: '10px',            
            '@media (max-width:767px)': {
                padding: '15px 20px',
                margin: '0',
                width: '100%',
                boxSizing: 'border-box',
                position:'fixed'
            }
        },
    }));