import React, { useContext, useEffect, useState } from "react";
import "./App.scss";
import { Route, Switch, useLocation } from "react-router-dom";
import loadable from '@loadable/component';
// import SuperAdminDashboard from "./pages/SuperAdminDashboard";
import DeviceInfoContext from '../src/components/NewHomePage/renderByDevice/deviceInfoContext';
import Helmet from "react-helmet";
import { BASE_URL, GTM_WEB_ID, twitterHandle, seotitle, seoDescription, ANALYTICS_URI, TITLE} from "./config";
import { COLORS } from "./assets/variables";
import ContainerComponent from "./pages/ContainerComponent";
import Context from "./context/Context";
import { initAmplitude } from "./utils/amplitude";

const FundraiserListingPage = loadable(() => import('../src/components/FundraiserList-UI-Rewamp'))
const ProgramLists = loadable(() => import("./pages/create/ProgramLists"))
const SelectFundraiser = loadable(() => import("./pages/create/SelectFundraiser"))
const FundraiserDetails = loadable(() => import("./pages/create/program/FundraiserDetails"))
const MedicalFundraiserDetails = loadable(() => import("./pages/create/MedicalFundraiserDetails"))
const MedicalFundraisersPendingTasks = loadable(() => import("./pages/pending-tasks/MedicalFundraisersPendingTasks"))
const Careers = loadable(() => import('./pages/Careers/Careers'))

const Aboutus = loadable(() => import('./pages/Aboutus/Aboutus'))
const NewHomepage = loadable(() => import('./pages/NewHomePage'))
const EditCampaign = loadable(() => import('./pages/EditCampaign'))
const Dashboard = loadable(() => import('./pages/Dashboard'))
const NewsLetter = loadable(() => import('./pages/NewsLetter/NewsLetter'))
const NewProfilePage = loadable(() => import('./pages/NewProfile/NewProfilePage'))
const CausesComponent = loadable(() => import('./components/FundraiserList-UI-Rewamp/FundraiserList'))
const NonprofitProfilePage = loadable(() => import('./pages/NonprofitProfilePage/NonprofitProfilePage'))
const SpotlightCampaignPage = loadable(() => import('./pages/SpotlightCampaign'))
const NgoOnboardingPage = loadable(() => import('./pages/NgoOnboarding'))
const ClaimGift =loadable(() => import('./pages/ClaimGift'))
const LocationPage = loadable(() => import('./pages/Location/Location'))
const CombineMonthlyMission =loadable(() => import('./pages/CombineMonthlyMission/CombineMonthlyMission'))
require("react-responsive-carousel/lib/styles/carousel.min.css");
require("react-datepicker/dist/react-datepicker.css");

type AppProps = {
	path?: string
}

const App = (props?: AppProps) => {
	//Amplitude Initiation 
	const context = useContext(Context);
	const userContext = context?.User
	const user = userContext?.state?.userData
	const [ varient, setVarient ] = useState('')
	const route = useLocation()

	useEffect(() => {
		initAmplitude(user);
	}, []);

	const pageProps = {
		device: global?.window?.innerWidth <= 700 ? 'mobile' : 'desktop',
		varient
	}
	useEffect(() => {
		const scrollToHash = () => {
		  if (route?.hash === "#Monthly-missions" && window && window !== undefined) {
			const element = document.getElementById("Monthly-missions");
			if (element) {
			  window.scrollTo({
				behavior: "smooth",
				top: element.offsetTop,
			  });
			}
		  }
		};
	
		const delayScroll = setTimeout(scrollToHash, 1000);
	
		return () => clearTimeout(delayScroll);
	  }, [route?.hash]);
	
	return (
		<div className="App">
			<Helmet>
				<title>{seotitle}</title>
				<meta name="apple-mobile-web-app-title" content="Give" />
				<meta name="application-name" content="Give" />
				<meta name="msapplication-TileColor" content={COLORS.primary} />
				<meta name="theme-color" content={COLORS.primary} />
				<meta name="description" content={seoDescription} />
				<meta property="fb:app_id" content={process.env.REACT_APP_FACEBOOK_APP_ID} />

				<meta property="og:type" content="website" />
				<meta property="og:title" content="Give" />
				<meta property="og:description" content={seoDescription} />
				<meta property="og:image" content="https://cfstatic.give.do/8961e30a-3b12-42a2-8c7f-783dedebbaa9.png" />
				<meta property="og:url" content={BASE_URL} />

				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content={BASE_URL} />
				<meta property="twitter:creator" content={twitterHandle} />
				<meta property="twitter:title" content={TITLE} />
				<meta property="twitter:description" content={seoDescription} />
				<meta property="twitter:image" content="https://cfstatic.give.do/3d1f7095-3493-4157-8f11-d91857b78bc8.png" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />

				<link rel="manifest" href={`${BASE_URL}/manifest.json`} />
				<link rel="preconnect" href="https://fonts.googleapis.com" />

				<link rel="canonical" href={BASE_URL} />
				<link rel="apple-touch-icon" sizes="180x180" href="https://cfstatic.give.do/2c25f897-faa4-460c-9043-165ef4950e44.png" type="image/png" />
				<link rel="icon" sizes="32x32" href="https://cfstatic.give.do/60dea720-4220-408d-8599-d17ae9e98d00.png" type="image/x-icon" />
				<link rel="icon" sizes="16x16" href="https://cfstatic.give.do/7a19e215-b886-4702-817c-067ac93713f7.png" type="image/x-icon" />
				<link rel="mask-icon" sizes="16x16" href="https://cfstatic.give.do/8a3fe478-7610-43fd-910b-8fb4028fc8cd.svg" color={COLORS.primary} />

				<link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="true" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
				<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossOrigin="anonymous" />
				<script defer={true} type="text/javascript" src={`${ANALYTICS_URI}?ID=${GTM_WEB_ID}`}></script>

				{/* Only rendering the application/ld+json value for server render is props.path is available and is equal to '/' */}
				{props?.path === '/' ? (					
					<script type="application/ld+json">
						{JSON.stringify({
							"@context": "https://schema.org", 
							"@type": "Organization", 
							"name": "Give.do", 
							"alternateName": "Give India", 
							"url": "https://give.do/", 
							"logo": "https://cfstatic.give.do/logo.png"
						})}
					</script>
				): null}
				{/* Only rendering the application/ld+json value for server render is props.path is available and is equal to '/' */}
				{props?.path === '/' ? (					
					<script type="application/ld+json">    
						{JSON.stringify({
							"@context": "https://schema.org/",
							"@type": "WebSite",
							"name": "Give.do",
							"url": "https://give.do/",
							"potentialAction": {
								"@type": "SearchAction",
								"target":{"@type":"EntryPoint","urlTemplate":"https://give.do/fundraisers/?q={search_term_string}"},
								"query-input": "required name=search_term_string"
							}
						})}  
					</script>					
				): null}


			</Helmet>
			{
				/**
				* Commenting this for future use, for notifcation ribbon use this
				  * <NotificationBar />
				  **/
			}
			<Switch>
				{/* NOTE: Url Parameters are getting truncated because of this line. hence commenting out. 
					TODO: Need to test all the happy flows */}
				{/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}

				<DeviceInfoContext.Provider value={pageProps}>
					<Route exact path="/" component={NewHomepage} />
					<Switch>
						<Route exact path="/newsletters" component={NewsLetter} />
						<Route exact path='/fundraisers/children' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/elderly-care' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/animal-welfare' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/health' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/help-the-poor' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/differently-abled' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/women' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/disaster-relief' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/save-environment' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/art-and-culture' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/sports' component={FundraiserListingPage} />
						<Route exact path='/fundraisers/human-rights' component={FundraiserListingPage} />
						<Route exact path="/fundraisers" component={FundraiserListingPage} />
						{/* <Route exact path="/fundraisers" component={Homepage} /> */}
						{/* <Route
							exact
							path="/profile"
							component={ProfilePage}
						/> */}
						{/* Redirect the user to New Profile page - Replace /fundraisers with new profile link */}
						{/* <Route exact path="/Profile">
							<Redirect to="/fundraisers" />
						</Route> */}

						<Route
							exact
							path={['/myprofile', '/mydonations', '/myfundraisers', '/mysupport']}
							component={NewProfilePage}
						/>
						<Route
							exact
							path="/aboutus"
							component={Aboutus}
						/>
						<Route
							exact
							path="/careers"
							component={Careers}
						/>
						<Route exact path="/newsletters" component={NewsLetter} />
						<Route exact path="/fundraisers/:identifier" component={ContainerComponent} />
						<Route exact path="/fundraisers/causes/:identifier" component={CausesComponent} />
						<Route
							exact
							path="/fundraisers/:identifier/jointeam/:teamSecret"
							component={ContainerComponent}
						/>
						<Route exact path="/fundraisers/edit/:identifier" component={EditCampaign} />
						<Route
							exact
							path="/fundraisers/pending-tasks/:identifier/"
							component={MedicalFundraisersPendingTasks}
						/>
						<Route exact path="/fundraisers/create/select" component={SelectFundraiser} />

						<Route
							exact
							path="/fundraisers/create/program-listing"
							component={ProgramLists}
						/>
						<Route
							path="/fundraisers/create/program/:program_slug"
							component={FundraiserDetails}
						/>
						<Route
							path="/fundraisers/create/nonprofit/:ngo_slug"
							component={FundraiserDetails}
						/>
						<Route
							path="/fundraisers/create/campaign/:campaign_slug"
							component={FundraiserDetails}
						/>
						<Route
							path="/fundraisers/create/:ngo_slug"
							component={FundraiserDetails}
						/>
						<Route
							exact
							path="/fundraisers/new/create/medical"
							component={MedicalFundraiserDetails}
						/>
						<Route path="/fundraisers/dashboard/:identifier" component={Dashboard} />
						<Route path="/ngos/:identifier" component={NonprofitProfilePage} />
						<Route path="/spotlight/:identifier" component={SpotlightCampaignPage} />
						<Route exact path="/partner" component={NgoOnboardingPage} />
						<Route exact path="/partner/charity" component={NgoOnboardingPage} />
						<Route exact path="/claim-gift-cards" component={ClaimGift} />
						<Route  path="/find-ngo-by-area/:location" component={LocationPage} />						
						<Route exact path="/monthly-missions" component={CombineMonthlyMission} />

						</Switch>
				</DeviceInfoContext.Provider>
			</Switch>
		</div>)

};

export default App;
