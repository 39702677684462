import { createContext } from "react";
import * as interfaces from "./interfaces";

interface context {
  Toast: interfaces.toast;
  Campaign: any;
  EditCampaign: any;
  Config: any;
  User: any;
  onCurrencyChange: any;
  StartFundraisers:any;
  Dashboard: any;
  contactFunc: any;
  sendMail:any;
  onCurrencyChangeSwitch:any;
  userIPContext: any;
  NonProfit:interfaces.NonProfit;
  cancelReason:any
  }

const Context = createContext<context | null>(null);

export default Context;
