import React, { useEffect, useState } from 'react';
import RenderByDeviceType from '../renderByDevice/renderByDeviceType';
import styles from './CarouselPanel.module.scss';
import clsx from 'clsx';
import LazyLoadImages from '../../../components/common/Image/LazyLoadImages';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "../../../assets/scss/imageGallery.scss";
import { COLORS } from '../../../assets/variables';
import { useLocation } from 'react-router';
import { getUTMParamWindowMethod } from '../../../utils/helpers';

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const CarouselPanel = React.memo((props: any) => {
    const { items, visibleByDefault, autoPlay=false, setAddStickyButtonOnVideo, renderGiveNow, thumbnailDataForVideo } = props;
    const onClickHandle = (e: any, link: any) => {
        e.preventDefault();

        if (link) {
            if (window) {
                window.location.href = link;
            }
        }
    };
    const [UTMParameters, setUTMParameters] = useState<any>(null)
    const location = useLocation();

    useEffect(() => {
        const getUTMParams = getUTMParamWindowMethod(String(location.search))
        setUTMParameters(getUTMParams)
    }, [])

    const slides = items.map((item: any, index: any) => {
        const mobileStartUtm = item.mobile_link && item.mobile_link.indexOf('?utm_source') - 1;
        const desktopStartUtm = item.desktop_link && item.desktop_link.indexOf('?utm_source') - 1;
        const linkStartUtm = item.link && item.link.indexOf('?utm_source') - 1;

        let linkWithUTMsource = item.link && item.link + `${UTMParameters?.utm_source ? '&utm_source=' + UTMParameters?.utm_source : ''}`
        const mobile_link = (mobileStartUtm > -1 ? item.mobile_link.substring(0, mobileStartUtm) :
            item.mobile_link) || (linkStartUtm > -1 ? item.link.substring(0, linkStartUtm) : linkWithUTMsource)

        const desktop_link = (desktopStartUtm > -1 ? item.desktop_link.substring(0, desktopStartUtm) :
            item.desktop_link) || (linkStartUtm > -1 ? item.link.substring(0, linkStartUtm) : linkWithUTMsource)

        const mobile = (
            <a href={mobile_link} onClick={(e) => onClickHandle(e, mobile_link)}>
                <LazyLoadImages visibleByDefault={visibleByDefault} imageIndex={index} src={item.src.mobile} alt={item.altText} className={clsx(styles.pointer, styles.w_100)} isVideoPresent={item?.altText === 'Gallery Video' ? true : false} videoUri={item.videoLink} setAddStickyButtonOnVideo={setAddStickyButtonOnVideo} thumbnailDataForVideo={thumbnailDataForVideo}/>
            </a>
        );

        const desktop = (
            <>
            {
                item?.altText === 'Gallery Video' ? 
                <LazyLoadImages visibleByDefault={visibleByDefault} imageIndex={index} src={item.src.desktop} alt={item.altText} className={clsx(styles.pointer, styles.w_100)} isVideoPresent={item?.altText === 'Gallery Video' ? true : false} videoUri={item.videoLink} setAddStickyButtonOnVideo={setAddStickyButtonOnVideo} renderGiveNow={renderGiveNow} thumbnailDataForVideo={thumbnailDataForVideo}/>
                :
                <a href={desktop_link} onClick={(e) => onClickHandle(e, desktop_link)} style={{position: 'relative'}}>
                <LazyLoadImages visibleByDefault={visibleByDefault} imageIndex={index} src={item.src.desktop} alt={item.altText} className={clsx(styles.pointer, styles.w_100)} isVideoPresent={item?.altText === 'Gallery Video' ? true : false} videoUri={item.videoLink} setAddStickyButtonOnVideo={setAddStickyButtonOnVideo} renderGiveNow={renderGiveNow} thumbnailDataForVideo={thumbnailDataForVideo}/>
                </a>
            }
            </>
        );

        return (
            <div key={index}>
                <RenderByDeviceType mobile={mobile} desktop={desktop} tablet={mobile} />
            </div>
        )
    });
    return (
      <React.Fragment>
        <div className="gallery">
          <Carousel
            autoPlay={autoPlay}
            interval={8000}
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            infiniteLoop={true}
            emulateTouch={true}
            swipeScrollTolerance={20}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeable={true}
            className="custom-carousel"
            showIndicators={slides.length > 1 ? true : false}
            renderIndicator={(
              onClickHandler: any,
              isSelected: boolean,
              index: number,
              label: string
            ) => {
              const defStyle = {
                margin: 5,
                color: COLORS.titleBorderColor,
                cursor: "pointer",
              };
              const style = isSelected
                ? { ...defStyle, color: COLORS.primary, opacity: 1 }
                : { ...defStyle };
              return (
                <span
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  // value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >
                  <li style={{ display: "table-cell", fontSize: "65px" }}>-</li>
                </span>
              );
            }}
          >
            {slides}
          </Carousel>
        </div>
        {/* <Slider {...sliderSettings} className="btn-slider" >
                {slides}
            </Slider> */}
      </React.Fragment>
    );
})



export default CarouselPanel
