import React from 'react';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import { Typography , makeStyles, useMediaQuery, useTheme, Box} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  subWrapper: {
    display:"flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding:"16px 24px",
    height: '45px',
    backgroundColor: "#222",
    [theme.breakpoints.up('sm')]: {
      borderRadius: `0 0 8px 8px`
    }
  },
  subText:{
    fontSize: `16px`,
    fontWeight: 600,
    lineHeight: `24px`,
    color:"#FFF"
  },
  closeButton: {
    // backgroundColor: `white`,
    borderRadius: `50%`,
    height: '48px',
    width: '48px',
    position: 'absolute',
    border: 'none',
    top: '-25px',
    right: '-45px',
    '& svg' : {
      fontSize: `40px`,
      fill: `white`
    },
    '&:hover': {
      backgroundColor: `transparent !important`
    },
    [theme.breakpoints.down('sm')]: {
        top: '24px',
        right: '24px',
        width: '30px',
        border: 'none',
        cursor: 'pointer',
        height: '30px',
        '& svg':{
          fontSize: "35px"
        },
    }
  }
}));

const VideoPlayer = (props:any) => {
  const { isOpen, closeModal, renderGiveNow} = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {
      <Modal isOpen={isOpen} ariaHideApp={false} 
       className="custom-modal"
       overlayClassName="custom-overlay"
      >
        <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
          <CloseIcon />
        </IconButton>
        <ReactPlayer
          url={props?.videoUri}
          width="100%"
          height={isMobile ? "" : "100%"}
          style={{maxHeight: isMobile ? '' : `438px`, minHeight: isMobile ? '275px' : ''}}
          className={isMobile ? "videoDesign" : ''}
          playing={true}
          controls
        />
        {
          isMobile ? null : 
          <div className={classes.subWrapper}>
            <Typography className={classes.subText}>
            With your support, we can create a huge impact for the cause 
            </Typography>
             <Box>{renderGiveNow}</Box>
          </div>
        }
      </Modal>
      }
    </div>
  );
};

export default VideoPlayer;