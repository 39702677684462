import * as interfaces from "../interfaces";

export const initialState: interfaces.startfundraisers = {
  projectsData: [],
  campaignsData: [],
  nonProfitsData: [],
  singleProjectData: {},
  singleNonProfitData: {},
  singleCampaignData:{},
  causeSelectedData: "All",
  primaryDetails: {},
  medicalDocuments: [],
  bankDetails: {},
  pendingTasksData: "patient-details",
};

export const reducer = (state: any, action: interfaces.action) => {
  switch (action?.type) {
    case "projects-data":
      return { ...state, projectsData: action.value };
    case "campaigns-data":
      return { ...state, campaignsData: action.value };
    case "nonprofits-data":
      return { ...state, nonProfitsData: action.value };
    case "single-project-data":
      return { ...state, singleProjectData: action.value };
    case "single-nonprofit-data":
      return { ...state, singleNonProfitData: action.value };
      case "single-campaign-data":
        return { ...state, singleCampaignData: action.value };
    case "cause-selected-data":
      return { ...state, causeSelectedData: `${action.value.charAt(0).toUpperCase() + action.value.slice(1)}` };
    case "primary-details":
      return { ...state, primaryDetails: action.value };
    case "medical-documents":
      return { ...state, medicalDocuments: action.value };
    case "bank-details":
      return { ...state, bankDetails: action.value };
    case "pending-tasks-data":
      return { ...state, pendingTasksData: action.value };
    case "reset":
      return initialState;
  }
};


