import React from 'react';
import Box from "@material-ui/core/Box";
import styles from "../../../HomepageRevamp/Header/Header.module.scss";

interface IProps{
    handleRegisterModal?:(btnPosition:number)=>void
}

const PartnerHeader = ({handleRegisterModal}:IProps) => {

    const navList = [
        { label: 'Our Events', id: 'our_events' },
        { label: 'Get Featured', id: 'get_featured' },
        { label: 'Success Stories', id: 'success_stories' },
        { label: 'Resources', id: 'resources' },
    ]
    const handleNavClick=(id:string)=>{
        const element = document.getElementById(id)
        if(element) element.scrollIntoView({behavior:'smooth',block:'nearest'})
    }
    return (
        <Box className={styles.h_rightwrapper}>
            <ul>
                {navList.map((_l) => (
                    <li onClick={()=>handleNavClick(_l.id)} key={_l.id}>
                        {_l.label}
                    </li>
                ))}
            </ul>
            <Box className={styles.h_btnwrapper}>
                <button
                    className="primary_cta ml-12"
                    onClick={()=>handleRegisterModal&&handleRegisterModal(1)}
                >
                    Sign up with give.do
                </button>
            </Box>
        </Box>
    )
}

export default React.memo(PartnerHeader)