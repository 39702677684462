export const COLORS = {
    primary: "#E7424B",
    secondary: "#F4A034",
    white: "#FFFFFF",
    black: "#000000",
    primaryGreen: "#039654",
    primaryDark: "#444444",
    secondaryDark: "#222222",
    tertiaryDark: "#666666",
    primaryTitleText: "#333333",
    iconColor: "#999999",
    textBlue:'#5925DC',

    primaryProgressBar: "#FBAF17",

    primaryText: "#101828",
    secondaryText: "#667085",

    primaryPink: "#EB5757",
    secondaryPink: "#EB57571A",

    textFocus: "#FAF9F9",
    secondaryOutline: "#DEDEDE",
    secondaryGrey: "#F9FAFB",

    buttonBorder: "#D0D5DD",
    infoTextColor: "#4F4F4F",
    buttonFocus: "#E9B8B9",
    titleBorderColor: "#E8E8E8",
    secondaryTextColor: "#656262",

    buttonHoverColor: "#F48587",
    buttonFocusColor: "#D1585E",
    dividerColor: "#E4E7EC",
    secondarySubtitle: "#27AE60",

    secondaryBlueWithOpacity: "rgba(47,128,237,0.1)",
    inputBorderColor: "#CED4DA",
    currencyBorderColor: "#80BDFF",
    successColor: "#ECFDF3",

    footerBgColor: "#F8F1F1",
    searchTextColor: "rgba(34,34,34,0.6)",
    paymentButtonColor: "#D5222C",

    cardBoxShadow: 'rgba(0, 0, 0, 0.08)',
    eventCardBackground: '#E9B8B929',
    eventTopBackground: '#FBE0BE',
experienceCardBackground:'rgba(255, 255, 255, 0.8)',
experienceCardTextColor:'rgba(102, 102, 102, 1)',
experienceCardNumberColor:'rgba(34,34,34,1)'
}

export const FONT_FAMILY = {
    primaryFont: "'Poppins', sans-serif",
    secondaryFont: "'Libre Baskerville', serif"
}